import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  tableBody: {
    marginBottom: 20,
  },
  headerActions: {
    width: 92,
  },
  cellAll: {
    flex: 1,
    marginRight: 10,
  },
});

export default styles;

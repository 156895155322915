import { RenderProps, StyleFn } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import React, { useMemo, useCallback } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import TitleBar from '../../TitleBar/TitleBar';

export type PickerOption = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
  isSelected?: boolean;
  testID?: string;
};
export type PickerSelectFn = (value: PickerOption['value']) => void;

export interface PickerModalProps {
  title?: string;
  testID?: string;
  options: Array<PickerOption>;
  onSelect: PickerSelectFn;
  value?: unknown;
  closeBtn?: React.ReactElement;
  onClose?: () => void;
}

const container: StyleFn = ({ theme }) => ({
  width: 320,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
  justifyContent: 'center',
});

const scrollView: StyleFn = ({ theme }) => ({
  maxHeight: 50 * 8,
  ...theme.shadow15,
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

export const CloseButton: React.FC<{ onPress: () => void }> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

const optionButtonStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  backgroundColor: theme.colors.white,
  ...theme.border.borderSolid,
  borderWidth: 0,
  borderBottomWidth: 1,
  height: 50,
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
});

// TODO: implement search, use FlatList, use this component for product search and customer search
const PickerOption: React.FC<PickerOption & { onPress: PickerSelectFn }> = ({
  isSelected,
  label,
  value,
  onPress,
  testID,
}) => {
  const { css } = useFela();
  return (
    <FelaComponent style={optionButtonStyle}>
      {({ style }: RenderProps) => (
        <Button
          fluid
          title={label}
          testID={testID}
          icon={isSelected ? 'check' : 'AngleRight'}
          iconPosition="right"
          containerStyle={style}
          labelStyle={css(optionLabelStyle)}
          iconProps={isSelected ? { success: true } : { primary: true }}
          onPress={onPress.bind(null, value)}
        />
      )}
    </FelaComponent>
  );
};

export const PickerModal: React.FC<PickerModalProps> = ({
  title,
  options,
  onSelect,
  value,
  testID,
  closeBtn,
  onClose = () => undefined,
}: PickerModalProps) => {
  const { closeModal } = useModal();
  const { css } = useFela();

  const onPressClose = useCallback(() => {
    closeModal();
    onClose();
  }, [closeModal, onClose]);
  const defaultCloseBtn = <CloseButton onPress={onPressClose} />;
  return (
    <View style={css(container)} testID={testID}>
      {title && (
        <TitleBar
          primary
          title={title}
          titleLeft={closeBtn ?? defaultCloseBtn}
          testID="title"
        />
      )}
      <ScrollView
        style={css(scrollView)}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {useMemo(
          () =>
            options.map(x => (
              <PickerOption
                testID={`picker-option-${x.label}`}
                isSelected={x.value == value}
                key={x.value}
                label={x.label}
                value={x.value}
                onPress={onSelect}
              />
            )),
          [options, onSelect, value],
        )}
      </ScrollView>
    </View>
  );
};

import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { auth0Config } from '../../../constants';
import { useAuthentication } from '../../../hooks/app/useAuthentication';
import { useUserProfile } from '../../../hooks/app/useUserProfile';
import LoadingScreen from '../../Loading/Loading';

// TODO: handle logout redirect to login screen
const CallbackScreen: React.FC = () => {
  const { getMe } = useUserProfile();
  const navigation = useNavigation();

  const onFinishLogin = useCallback(async () => {
    try {
      await getMe();
      navigation.navigate('BackOffice');
    } catch (error) {
      navigation.navigate('LoginTypeSelection');
    }
  }, [getMe, navigation]);

  useAuthentication({
    redirectUrl: auth0Config.redirectUrl,
    onAuthorized: onFinishLogin,
  });

  // redirect to Login screen after the timeout for trying complete the authorization flow
  // cancel the timeout if the screen navigate away
  useFocusEffect(
    useCallback(() => {
      const timerId = setTimeout(() => {
        navigation.navigate('LoginTypeSelection');
      }, 5000);
      return () => {
        clearTimeout(timerId);
      };
    }, [navigation]),
  );

  return <LoadingScreen />;
};

export default CallbackScreen;

import { useTranslation } from '@oolio-group/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ActivityIndicator } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../hooks/Notification';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import FormInput from '../../FormInput/FormInput';
import { CreateTerminalInput, StyleFn } from '@oolio-group/domain';
import { useTerminals } from '../../../hooks/app/useTerminals';

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const containerStyle: StyleFn = ({ theme }) => ({
  width: 350,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: 38,
  marginBottom: theme.spacing.medium,
});

const formTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

const textInputStyle: StyleFn = () => ({
  height: 38,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 3.5,
  // width: '80%',
  alignSelf: 'center',
});

const ConnectPaymentTerminal: React.FC = () => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { loading, error, terminal, createTerminal } = useTerminals();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (terminal) {
      showNotification({
        success: true,
        message: translate('paymentTerminal.createdSuccessfully'),
      });
      closeModal();
    }
  }, [closeModal, showNotification, terminal, translate]);

  const [form, setForm] = useState<CreateTerminalInput>({
    name: '',
    uuid: '',
  });

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={onCloseModal}
    />
  );

  const onChange = useCallback((prop: string, value) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSubmitForm = useCallback(() => {
    if (typeof form.name === 'string' && form.name.trim() === '') {
      showNotification({
        error: true,
        message: translate('form.paymentTerminal.nameIsRequired'),
      });
      return;
    }

    if (typeof form.uuid === 'string' && form.uuid.trim() === '') {
      showNotification({
        error: true,
        message: translate('form.paymentTerminal.terminalIdIsRequired'),
      });
      return;
    }

    createTerminal({
      name: form.name,
      uuid: form.uuid,
    });
  }, [createTerminal, form.name, form.uuid, showNotification, translate]);

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        primary
        title={translate('paymentTerminal.connectTerminal')}
      />
      <PopupView containerStyle={css(popupStyle)}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <FormInput
              testID="paymentTerminalName"
              title={translate('form.paymentTerminal.name')}
              placeholder={translate('form.paymentTerminal.namePlaceholder')}
              value={form.name}
              containerStyle={css(formInputStyle)}
              textStyle={css(formTextStyle)}
              fluid
              onChangeText={onChange.bind(null, 'name')}
              textInputStyle={css(textInputStyle)}
            />

            <FormInput
              testID="paymentTerminalTerminalId"
              title={translate('form.paymentTerminal.terminalId')}
              placeholder={translate(
                'form.paymentTerminal.terminalIdPlaceholder',
              )}
              value={form.uuid}
              fluid
              containerStyle={css(formInputStyle)}
              textStyle={css(formTextStyle)}
              onChangeText={onChange.bind(null, 'uuid')}
              textInputStyle={css(textInputStyle)}
            />

            <Button
              title={translate('paymentTerminal.connectTerminal')}
              testID="connectTerminal"
              success
              fluid
              size="small"
              containerStyle={css(buttonStyle)}
              onPress={onSubmitForm}
            />
          </>
        )}
      </PopupView>
    </View>
  );
};

export default ConnectPaymentTerminal;

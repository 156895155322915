import { useCallback, useMemo } from 'react';
import { useSession } from './useSession';
import { AppScreen } from '../../types/AppScreen';
import { useNavigation } from '@react-navigation/native';
import { StackActions } from '@react-navigation/native';
import { BehaviorSubject } from 'rxjs';

export interface usePostSalesNavigationType {
  navigateToPostSaleScreen: (fromCustomerBalance?: boolean) => void;
}

export const postSalesObservableForLogin = new BehaviorSubject<boolean>(false);
export const postSalesObservableForCustomerBalance =
  new BehaviorSubject<boolean>(false);

export const usePostSalesNavigation = (): usePostSalesNavigationType => {
  const [session] = useSession();
  const navigation = useNavigation();
  const { postSaleScreen } = session.deviceProfile || {};
  const screenAfterSales = postSaleScreen
    ? AppScreen[postSaleScreen]
    : AppScreen.NEW_ORDER;

  const navigateToPostSaleScreen = useCallback(
    async (fromCustomerBalance?) => {
      if (fromCustomerBalance) {
        postSalesObservableForCustomerBalance.next(true);
      }
      if (screenAfterSales === AppScreen.NEW_ORDER) {
        navigation.navigate('Orders', {
          screen: screenAfterSales,
          params: {
            id: undefined,
            isExisting: false,
          },
        });
      } else if (screenAfterSales == AppScreen.LOGIN) {
        postSalesObservableForLogin.next(true);
      } else if (screenAfterSales !== AppScreen.ORDER_HISTORY) {
        const jumpToAction = StackActions.push(screenAfterSales);
        navigation.dispatch(jumpToAction);
      } else {
        navigation.navigate(screenAfterSales);
      }
    },
    [navigation, screenAfterSales],
  );

  return useMemo(
    () => ({
      navigateToPostSaleScreen: navigateToPostSaleScreen,
    }),
    [navigateToPostSaleScreen],
  );
};

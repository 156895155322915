import React from 'react';
import { View } from 'react-native';
import { ProductType, ProfitMargin } from '@oolio-group/domain';
import theme from '../../../../../common/default-theme';
import styles from './ProfitMargins.styles';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatPicker from '../../../../../components/Shared/Select/Picker';

interface Props {
  index: number;
  profitMargin: ProfitMargin;
  productTypes: ProductType[];
  onChange: (prop: string, index: number, value: string) => void;
  onDelete: (index: number) => void;
}

export const ProfitMarginRow: React.FC<Props> = ({
  index,
  profitMargin,
  productTypes,
  onChange,
  onDelete,
}) => {
  if (
    profitMargin.productTypeId &&
    !productTypes.some(pt => pt.id === profitMargin.productTypeId)
  )
    return null;

  return (
    <View testID="row-margin" style={theme.tables.row}>
      <TreatPicker
        testID="select-type"
        options={productTypes.map(productType => ({
          value: productType.id,
          label: productType.name,
        }))}
        selectedValue={profitMargin.productTypeId}
        onValueChange={onChange.bind(null, 'productTypeId', index)}
        containerStyle={styles.cellGroup}
      />
      <InputText
        testID="input-margin"
        label="%"
        value={`${profitMargin.margin || 0}`}
        placeholder="0.00"
        keyboardType="numeric"
        onChangeText={onChange.bind(null, 'margin', index)}
        alignText="right"
        containerStyle={styles.cellMargin}
        onBlur={() =>
          onChange(
            'margin',
            index,
            (parseFloat(profitMargin.margin.toString()) || 0).toFixed(2),
          )
        }
      />
      <ButtonIcon
        icon="trash-alt"
        type="negativeLight"
        onPress={onDelete.bind(null, index)}
      />
    </View>
  );
};

import { Order } from '@oolio-group/domain';
import fetch from 'cross-fetch';
import { REACT_APP_INTEGRITY_URL } from 'react-native-dotenv';
import { tokenUtility } from '../state/tokenUtility';
import { stripProperties } from './stripObjectProps';
import { getSession } from '../state/preferences';
import { Tracer } from '@oolio-group/tracer-client';

// TODO : Refactor API Url to be /api and use paths
const URI_PATH = '/order/verify';
const ORDER_DETAIL = '/order/';

const ORDER_INTEGRITY_URI =
  process.env['REACT_APP_INTEGRITY_URL'] ||
  REACT_APP_INTEGRITY_URL ||
  'http://localhost:4000';

const fetchEvents = async (order: Order) => {
  const sanitisedOrder = stripProperties({ ...order }, '__typename');
  try {
    const session = await getSession();
    const result = await fetch(ORDER_INTEGRITY_URI + URI_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenUtility.token as string,
        device: session?.device?.id as string,
        organization: session?.currentOrganization?.id as string,
        venue: session?.currentVenue?.id as string,
        store: session?.currentStore?.id as string,
      },

      body: JSON.stringify({
        order: sanitisedOrder,
      }),
    });

    const data = await result.json();
    return data?.events || [];
  } catch (e) {
    Tracer.getInstance().captureException(e);
    return [];
  }
};

export const validateOrderEvents = async (order: Order) => {
  return await fetchEvents(order);
};

export const getOrderByIntegrity = async (
  orderId: string,
): Promise<Order | undefined> => {
  try {
    const session = await getSession();
    const result = await fetch(ORDER_INTEGRITY_URI + ORDER_DETAIL + orderId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenUtility.token as string,
        device: session?.device?.id as string,
        organization: session?.currentOrganization?.id as string,
        venue: session?.currentVenue?.id as string,
        store: session?.currentStore?.id as string,
      },
    });

    if (result.ok) {
      const data = await result.json();
      return data?.data as Order;
    }
    return undefined;
  } catch (e) {
    Tracer.getInstance().captureException(e);
    return undefined;
  }
};

import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.teritiary2,
  },
  tab: {
    flex: 1,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
  },
  activeTab: {
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  badge: {
    width: 12,
    height: 12,
    borderRadius: 12,
    backgroundColor: theme.colors.states.focus,
    position: 'absolute',
    top: 6,
    right: 6,
  },
});

export default styles;

import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './Navigation.styles';
import theme from '../../../common/default-theme';

export interface NavigationItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  title: string;
  routeName?: string;
  screenName?: string;
  onPress: (routeName: string, screenName?: string) => void;
}

export const NavigationItem: React.FC<NavigationItemProps> = ({
  title,
  routeName,
  screenName,
  onPress,
  icon: IconComponent,
}) => {
  const onPressButton = useCallback(() => {
    onPress(routeName || '', screenName);
  }, [onPress, routeName, screenName]);

  return (
    <TouchableOpacity
      sentry-label={title}
      onPress={onPressButton}
      style={styles.tab}
    >
      <Text style={styles.tabTitle}>{title}</Text>
      <View style={styles.tabIcon}>
        <IconComponent size={18} color={theme.colors.white} />
      </View>
    </TouchableOpacity>
  );
};

import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  filters: {
    margin: 20,
    flexDirection: 'row',
  },
  search: {
    flex: 1,
    marginHorizontal: 10,
  },
  dropdown: {
    width: 180,
  },
  datePicker: {
    width: 180,
    flexDirection: 'row',
    paddingHorizontal: 12,
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
  },
  dateLabel: {
    flex: 1,
  },
  orderTypeDropdown: {
    marginHorizontal: 10,
  },
  errorContainer: {
    flex: 1,
    margin: 20,
    padding: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  errorText: {
    marginTop: 20,
    textAlign: 'center',
    color: theme.colors.dark,
  },
});

export default styles;

import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import IconButton from '../../../components/Button/IconButton';
import { useSession } from '../../../hooks/app/useSession';
import Gradient from '../../../components/Gradient/Gradient';
import Logo from '../../../components/Logo/Logo';
import { NotificationList } from '../../../components/Notification/NotificationList';
import {
  useNotification,
  useNotificationsList,
} from '../../../hooks/Notification';
import theme from '../../../common/default-theme';

interface LayoutProps {
  leftIcon: string;
  onLeftIconPress?: () => void;
  children: React.ReactElement | React.ReactElement[];
}

export const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: theme.layoutWidth.m,
    height: '85%',
    padding: 40,
    borderRadius: 6,
    alignItems: 'center',
    backgroundColor: theme.colors.white,
  },
  backButtonContainer: {
    height: 44,
    width: 44,
    backgroundColor: theme.colors.grey1,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 10,
    left: 10,
  },
  logo: {
    marginTop: 20,
    marginBottom: 40,
    alignSelf: 'center',
  },
  logoImage: {
    width: '100%',
    height: 100,
    resizeMode: 'contain',
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    marginTop: 20,
    marginBottom: 40,
  },
});

const Layout: React.FC<LayoutProps> = ({
  leftIcon,
  onLeftIconPress = () => undefined,
  children,
}: LayoutProps) => {
  const [session] = useSession();
  const { closeNotification } = useNotification();
  const notifications = useNotificationsList();

  return (
    <Gradient style={styles.screenContainer}>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
      <View style={styles.modalContainer}>
        <View style={styles.backButtonContainer}>
          <IconButton
            icon={leftIcon}
            iconColor={theme.colors.black1}
            onPress={onLeftIconPress}
            iconSize={24}
          />
        </View>
        {!!session.currentOrganization?.logoUrl ? (
          <Image
            style={styles.logoImage}
            source={{
              uri: session.currentOrganization?.logoUrl,
            }}
          />
        ) : (
          <Logo type="logotype" color="primary" style={styles.logo} />
        )}
        {children}
      </View>
    </Gradient>
  );
};

export default Layout;

import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: theme.layoutWidth.s,
  },
  title: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    marginRight: 44,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  content: {
    padding: 30,
    paddingTop: 14,
    marginTop: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  contentRow: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentTitle: {
    flex: 1,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  contentValue: {
    lineHeight: 20,
    color: theme.colors.dark,
  },
});

export default styles;

import React, { useCallback, useMemo, useRef } from 'react';
import { View, Text } from 'react-native';
import {
  DEFAULT_TABLE_ABBREVIATION,
  OrderItem,
  OrderTypeCode,
  PaymentAction,
  PaymentType,
  SplitPaymentBy,
  OrderPaymentStatus,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { groupBy } from 'lodash';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { useNetworkStatusVar } from '../../../hooks/app/useNetworkStatusVar';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../../screens/POS/Payments/PaymentScreen';
import { analyticsService } from '../../../analytics/AnalyticsService';
import styles from './PaymentButtons/PaymentButtons.styles';
import KeypadModal from '../Modals/Keypad/KeypadModal';
import TreatButton from '../../Shared/TreatButton/TreatButton';

export const SPLIT_PAYMENT_EQUAL_OPTIONS = [
  {
    splitNumber: 2,
    label: '2',
  },
  {
    splitNumber: 3,
    label: '3',
  },
  {
    splitNumber: 4,
    label: '4',
  },
  {
    splitNumber: undefined,
    label: 'Other',
  },
];

export enum SplitPaymentOption {
  'SPLIT_EQUALLY' = 'SPLIT_EQUALLY',
  'SPLIT_BY_AMOUNT' = 'SPLIT_BY_AMOUNT',
  'SPLIT_BY_SEAT' = 'SPLIT_BY_AMOUNT',
}

interface SplitSplitPaymentOptionsProps {
  showSplitBySeat?: boolean;
  dispatch: React.Dispatch<PaymentActionProp>;
  orderPaymentDetails: OrderPaymentDetails;
  orderTypeId?: string;
  orderItems: OrderItem[];
  onUpdateProcessingAmount: (type: 'SET' | 'UPDATE') => void;
  lastSplitPaymentBy?: SplitPaymentBy;
}

export interface OtherPaymentType extends PaymentType {
  color?: string;
}

const SplitPaymentOptions: React.FC<SplitSplitPaymentOptionsProps> = ({
  dispatch,
  orderTypeId,
  orderItems,
  onUpdateProcessingAmount,
  lastSplitPaymentBy,
  orderPaymentDetails,
}) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const [session] = useSession();

  const isSplitByProductDisabled = orderPaymentDetails.isCustomerBalancePayment;

  const orderTypeCode = session.deviceProfile?.orderTypes?.find(
    orderType => orderType.id === orderTypeId,
  )?.code;

  const isSeatManagementEnabled =
    session?.deviceProfile?.enableSeatManagement &&
    orderTypeCode === OrderTypeCode.DINE_IN;

  const itemBySeats = useMemo(
    () =>
      groupBy(
        orderItems,
        item => item.seatNumber || DEFAULT_TABLE_ABBREVIATION,
      ),
    [orderItems],
  );

  const occupiedSeats = useMemo(() => {
    return Object.keys(itemBySeats)
      .filter(seat => seat !== DEFAULT_TABLE_ABBREVIATION)
      .map(seatNumber => ({
        seatNumber,
        isPaid: itemBySeats[seatNumber].every(
          orderItem => orderItem.paymentStatus === OrderPaymentStatus.COMPLETE,
        ),
      }));
  }, [itemBySeats]);

  const showPaymentBySeats =
    isSeatManagementEnabled && occupiedSeats.length > 0;

  const networkStatus = useNetworkStatusVar();
  const networkStatusRef = useRef<boolean>(true);
  networkStatusRef.current = networkStatus;

  const submitSplitPaymentCount = useCallback(
    (splitNumber: number) => {
      dispatch({
        type: PaymentAction.SETUP_NUMBER_OF_SPLIT_PAYMENT,
        payload: {
          numberOfSplitPayment: splitNumber,
        },
      });
    },
    [dispatch],
  );

  const showSetNumberOfSplitPaymentModal = useCallback(() => {
    showModal(
      <KeypadModal
        mode="integer"
        initialValue="5" // Default Split Option for Other
        title={translate('setSplitPaymentCount.title')}
        onConfirm={submitSplitPaymentCount}
        onDismiss={closeModal}
      />,
    );
  }, [showModal, translate, submitSplitPaymentCount, closeModal]);

  const onClickSplitEqual = useCallback(
    (splitNumber?: number) => {
      if (splitNumber) {
        analyticsService.capture('split_payment', {
          action: 'Equally',
          amount: splitNumber,
        });
        submitSplitPaymentCount(splitNumber);
      } else {
        showSetNumberOfSplitPaymentModal();
      }
    },
    [showSetNumberOfSplitPaymentModal, submitSplitPaymentCount],
  );

  const onSplitBySeat = useCallback(() => {
    analyticsService.capture('split_payment', {
      action: 'By Seat',
    });

    const allowToSplitBySeat =
      !lastSplitPaymentBy || lastSplitPaymentBy === SplitPaymentBy.BY_SEAT;
    if (!allowToSplitBySeat) {
      return showNotification({
        message: translate(
          lastSplitPaymentBy === SplitPaymentBy.BY_AMOUNT
            ? 'payment.splitBySeatDisabledAsPaidByAmount'
            : 'payment.splitBySeatDisabledAsPaidByProduct',
        ),
        error: true,
      });
    }
    dispatch({
      type: PaymentAction.SPLIT_BY_SEAT,
      payload: {},
    });
  }, [dispatch, lastSplitPaymentBy, showNotification, translate]);

  const onSplitByProduct = useCallback(() => {
    analyticsService.capture('split_payment', {
      action: 'By Product',
    });

    const allowToSplitByProduct =
      !lastSplitPaymentBy || lastSplitPaymentBy === SplitPaymentBy.BY_PRODUCT;

    if (!allowToSplitByProduct) {
      return showNotification({
        message: translate(
          lastSplitPaymentBy === SplitPaymentBy.BY_AMOUNT
            ? 'payment.splitByProductDisabledAsPaidByAmount'
            : 'payment.splitByProductDisabledAsPaidBySeat',
        ),
        error: true,
      });
    }

    dispatch({
      type: PaymentAction.SPLIT_BY_PRODUCT,
      payload: {},
    });
  }, [dispatch, lastSplitPaymentBy, showNotification, translate]);

  return (
    <View style={styles.container}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={{ marginBottom: 30 }}>
        <Text style={styles.groupTitle}>{translate('payment.splitEqual')}</Text>
        <View style={styles.fixedOption}>
          {SPLIT_PAYMENT_EQUAL_OPTIONS.map(splitOption => (
            <TreatButton
              key={`split-equally-${splitOption.label}`}
              testID={`split-equally-${splitOption.label}`}
              label={splitOption.label}
              onPress={() => onClickSplitEqual(splitOption.splitNumber)}
              type="neutral"
              height={60}
              containerStyle={styles.btnOption}
            />
          ))}
        </View>
      </View>
      <View>
        <Text style={styles.groupTitle}>
          {translate('payment.otherSplitOptions')}
        </Text>
        <View style={styles.fixedOption}>
          <TreatButton
            testID="btn-splitByAmount"
            type="focus"
            height={60}
            label={translate('payment.splitByAmount')}
            onPress={() => {
              analyticsService.capture('split_payment', {
                action: 'By Amount',
              });
              onUpdateProcessingAmount('SET');
            }}
            containerStyle={styles.btnOption}
          />
          <TreatButton
            testID="btn-splitByProduct"
            type="focus"
            height={60}
            label={translate('payment.splitByProduct')}
            disabled={isSplitByProductDisabled}
            onPress={onSplitByProduct}
            containerStyle={styles.btnOption}
          />
          {showPaymentBySeats ? (
            <TreatButton
              testID="btn-splitBySeat"
              type="focus"
              height={60}
              label={translate('payment.splitBySeat')}
              disabled={isSplitByProductDisabled}
              onPress={onSplitBySeat}
              containerStyle={styles.btnOption}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default SplitPaymentOptions;

import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  Customer,
  OrderAction,
  OrderStatus,
  OrderType,
  Table,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import format from 'date-fns/format';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import { useSyncOrderEvents } from '../../../../hooks/app/useSyncOrderEvents';
import { userUtility } from '../../../../state/userUtility';
import { generateNotificationEvent } from '../../../../utils/sendReceiptHelper';
import styles from '../Customers.styles';
import theme from '../../../../common/default-theme';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import PrintReceiptButton from '../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';
import OrderReceiptActionModal from '../../../../components/Modals/SendOrderReceipt/OrderReceiptAction';
import { PaymentTypeDisplay } from '../../Orders/OpenOrders/OpenOrders.types';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
}

export interface OnAccountItem {
  orderNumber: string;
  id: string;
  status: OrderStatus;
  createdAt: number;
  completedAt: number;
  customer: Customer;
  orderName?: string;
  customerName: string;
  orderType: OrderTypeDisplayProps;
  payTypes: Array<PaymentTypeDisplay>;
  totalValue: number;
  table: Table;
}

export interface OnAccountOrderViewProps {
  data: OnAccountItem[];
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onPressRePrintDocket: (orderId: string) => void;
  onSelectOrder?: (id: string) => void;
  selectedPage: number;
  onChangeSelectedPage: (page: number) => void;
  isOffline?: boolean;
  onPressRow: (id: string) => void;
  itemsPerPage: number;
}

const OnAccountOrdersTable: React.FC<OnAccountOrderViewProps> = ({
  data,
  selectedPage,
  onPressPrintReceipt,
  onPressRePrintDocket,
  onChangeSelectedPage,
  isOffline,
  onPressRow,
  itemsPerPage,
}: OnAccountOrderViewProps) => {
  const [session] = useSession();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showNotification } = useNotification();

  const onSyncComplete = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
  };

  const { syncOrderEvents } = useSyncOrderEvents(onSyncComplete);

  const pageItems = useMemo(() => {
    return data?.slice(
      (selectedPage - 1) * itemsPerPage,
      selectedPage * itemsPerPage,
    );
  }, [data, selectedPage, itemsPerPage]);

  const sendOrderReceipt = useCallback(
    async (order, email, phone) => {
      if (session && order?.id) {
        const event = generateNotificationEvent(
          session,
          order?.id,
          userUtility.userActivity.posUser?.id || '',
          email,
          phone,
        );

        if (event) {
          syncOrderEvents([event]);
        }
      }
    },
    [session, syncOrderEvents],
  );

  const handleRowPress = useCallback(
    (orderId: string) => {
      if (isOffline) {
        showNotification({
          error: true,
          message: translate('settings.noInternet'),
        });
      } else {
        onPressRow(orderId);
      }
    },
    [isOffline, onPressRow, showNotification, translate],
  );

  const showPrintModal = useCallback(
    order => {
      if (order) {
        showModal(
          <OrderReceiptActionModal
            order={order}
            sendReceipt={sendOrderReceipt}
          />,
        );
      }
    },
    [sendOrderReceipt, showModal],
  );

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.cellType]}>
          {translate('openOrders.type')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellNo]}>
          {translate('orderHistory.order')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDate]}>
          {translate('onAccount.completedOn')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellFlex]}>
          {translate('onAccount.paymentMethod')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellAmount]}>
          {translate('onAccount.total')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellAmount]}>
          {translate('onAccount.balance')}
        </Text>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={{ width: 110 }} />
      </View>
      <View style={styles.tableBody}>
        {pageItems.length > 0 ? (
          pageItems.map((order, i) => {
            const isDineInOrder = order.orderType.code === 'D';

            const onAccountPaymentObj = order.payTypes.find(
              pay => pay.name === OrderAction.ON_ACCOUNT_PAYMENT_TYPE,
            );

            const outstandingBalance = onAccountPaymentObj?.amount || 0;

            return (
              <TouchableOpacity
                key={i}
                style={styles.tableRow}
                onPress={() => handleRowPress(order.id)}
              >
                <Sticker
                  testID="order-type"
                  type={isDineInOrder ? 'neutralLight' : 'teal'}
                  label={
                    order.table && isDineInOrder
                      ? order?.table.name
                      : order.orderType.code
                  }
                  containerStyle={styles.cellType}
                />
                <Sticker
                  testID="order-no"
                  type={isDineInOrder ? 'neutralLight' : 'teal'}
                  label={order.orderNumber.slice(0, -9)}
                  containerStyle={styles.cellNo}
                />
                <Text style={styles.cellDate}>
                  {format(order.completedAt, 'dd/MM/yy hh:mm a')}
                </Text>
                <Text style={styles.cellFlex}>
                  {order.payTypes
                    ?.map(
                      payType =>
                        `${payType.name} (${formatCurrency(payType.amount)})`,
                    )
                    .join(', ')}
                </Text>
                <Text style={styles.cellAmount}>
                  {formatCurrency(order.totalValue)}
                </Text>
                <Text style={styles.cellAmount}>
                  {formatCurrency(outstandingBalance)}
                </Text>
                <PrintReceiptButton
                  options={order.payTypes}
                  onPrintReceipt={paymentIndex =>
                    onPressPrintReceipt(order.id, paymentIndex)
                  }
                  reprintDocket={() => {
                    onPressRePrintDocket(order.id);
                  }}
                  orderStatus={OrderStatus.COMPLETED}
                />
                <ButtonIcon
                  icon="envelopeAlt"
                  type="neutralLight"
                  onPress={() => showPrintModal(order)}
                  containerStyle={styles.btnReceipt}
                />
              </TouchableOpacity>
            );
          })
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('customerView.emptyOrders')}
            </Text>
          </View>
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={selectedPage}
          onPageChange={onChangeSelectedPage}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default OnAccountOrdersTable;

import { Organization } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import React, { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import theme from '../../../common/default-theme';
import Search from '../../../components/Shared/Search/Search';
import styles from './OrganizationList.styles';

interface OrganizationListProps {
  onSelect?: (org: Pick<Organization, 'id' | 'name'>) => void;
  onSearch?: (searchString: string) => void;
  organizations: Pick<Organization, 'id' | 'name'>[];
  loading?: boolean;
}

const OrganizationsList: React.FC<OrganizationListProps> = ({
  onSelect = () => undefined,
  organizations,
  loading = false,
}: OrganizationListProps) => {
  const { translate } = useTranslation();

  const [searchText, setSearchText] = useState<string>('');

  const filteredOrgs = useMemo(() => {
    return organizations.filter(org => {
      if (org.name.toLowerCase().includes(searchText.toLowerCase()))
        return true;
    });
  }, [searchText, organizations]);

  return (
    <View style={styles.content}>
      <Text style={styles.title}>
        {translate('assignRegister.selectOrganization')}
      </Text>
      <Search
        testID="search"
        maxLength={50}
        onChangeText={setSearchText}
        placeholder={translate('backOfficeVenues.searchOrg')}
        containerStyle={styles.search}
      />
      {loading ? (
        <View style={styles.loading}>
          <ActivityIndicator size={20} color={theme.colors.primary} />
        </View>
      ) : (
        <View style={styles.table}>
          <ScrollView>
            {filteredOrgs.length > 0 ? (
              filteredOrgs.map(org => (
                <View key={org.id} style={styles.group}>
                  <TouchableOpacity
                    style={styles.rowParent}
                    onPress={() => onSelect(org)}
                  >
                    <Text style={styles.textParent}>{org.name}</Text>
                  </TouchableOpacity>
                </View>
              ))
            ) : (
              <View style={styles.loading}>
                <Text style={styles.emptyText}>
                  {translate('DeviceSelection.noOrgs')}
                </Text>
              </View>
            )}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

export default OrganizationsList;

/* eslint-disable react-native/no-inline-styles */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import {
  Device,
  DeviceMode,
  CreateDeviceInput,
  DeviceProfile,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import Search from '../../../components/Shared/Search/Search';
import Icon from '../../../components/Icon/Icon';
import theme from '../../../common/default-theme';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import { useDevices } from '../../../hooks/app/useDevices';
import { useDeviceProfiles } from '../../../hooks/app/useDeviceProfiles';
import { useSession } from '../../../hooks/app/useSession';
import { isEmpty } from 'lodash';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationDialog from '../../..//components/Modals/ConfirmationDialog';
import Modal from '../../..//components/Shared/Modals/Modal/Modal';
import InputText from '../../..//components/Shared/Inputs/InputText';
import Picker from '../../..//components/Shared/Select/Picker';
import pick from 'lodash/pick';

interface DeviceListProps {
  onSelect?: (device: Device) => void;
  loading: boolean;
}

const styles = StyleSheet.create({
  subTitleText: {
    lineHeight: 20,
    fontSize: 16,
    paddingVertical: 14,
    color: theme.colors.black1,
  },
  searchContainer: {
    width: theme.layoutWidth.s,
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    width: theme.layoutWidth.s,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: theme.layoutWidth.s,
  },
  row: {
    width: '100%',
    height: 68,
    backgroundColor: theme.colors.grey1,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 10,
  },
  rowTextContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
  },
  deviceNameText: {
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    color: theme.colors.black1,
  },
  deviceDetailsText: {
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    color: theme.colors.grey6,
  },
  errorText: {
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    fontSize: 12,
    color: theme.colors.states.negative,
  },
  formInputStyle: {
    marginVertical: 10,
  },
});

const DeviceList: React.FC<DeviceListProps> = ({
  onSelect = () => undefined,
  loading,
}: DeviceListProps) => {
  const [session] = useSession();
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isNameValid, setIsNameValid] = useState<boolean | undefined>(
    undefined,
  );
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const {
    devices,
    createDevice,
    getDevices,
    loading: loadingDevices,
  } = useDevices({
    storeId: session.currentStore?.id,
  });

  const {
    deviceProfiles,
    getAllDeviceProfiles,
    loading: loadingDeviceProfiles,
  } = useDeviceProfiles({
    storeId: session.currentStore?.id,
    venueId: session.currentVenue?.id,
  });
  const [devicesList, setDevicesList] = useState<Device[]>([]);

  const initialForm = {
    isCreatedByBackoffice: true,
    deviceProfile: '',
    mode: DeviceMode.F_POS,
  };

  const [form, setForm] = useState({
    ...initialForm,
  } as unknown as CreateDeviceInput);

  const onChange = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
    if (prop === 'name') {
      setIsNameValid(undefined);
    }
  }, []);

  const createNewDevice = useCallback(() => {
    const input = pick(
      {
        ...form,
        store: session.currentStore?.id || '',
        venue: session.currentVenue?.id || '',
        mode: DeviceMode.F_POS,
      },
      [
        'name',
        'deviceProfile',
        'store',
        'venue',
        'mode',
        'isCreatedByBackoffice',
      ],
    ) as unknown as CreateDeviceInput;
    createDevice(input);
  }, [createDevice, session.currentStore?.id, session.currentVenue?.id, form]);

  const deviceProfilesOptions = useMemo(() => {
    const allDeviceProfiles: DeviceProfile[] =
      Object.values(deviceProfiles) || [];

    const filteredDeviceProfiles = allDeviceProfiles
      .filter(
        (deviceProfile: DeviceProfile) => deviceProfile.mode === form.mode,
      )
      .map(deviceProfile => ({
        value: deviceProfile.id,
        label: deviceProfile.name,
      }));
    return filteredDeviceProfiles;
  }, [deviceProfiles, form.mode]);

  const onSubmit = useCallback(() => {
    if (!form.name || isEmpty(form.name)) {
      setIsNameValid(false);
    } else {
      createNewDevice();
      setIsCreateModalVisible(false);
    }
  }, [createNewDevice, form]);

  const onSearch = useCallback(
    (searchString: string) => {
      const result: Device[] = [];
      Object.values(devices).map(device => {
        if (
          device.name.toLowerCase().includes(searchString.toLowerCase()) &&
          device.mode === DeviceMode.F_POS
        ) {
          result.push({ ...device } as unknown as Device);
          return;
        }
      });
      setDevicesList(result);
    },
    [devices],
  );

  useEffect(() => {
    getDevices();
    getAllDeviceProfiles();
  }, [getAllDeviceProfiles, getDevices]);

  useEffect(() => {
    async function updateData() {
      const defaultProfile = Object.values(deviceProfiles)?.find(
        deviceProfile => deviceProfile.isDefault == true,
      );
      setForm(form => ({
        ...form,
        deviceProfile: defaultProfile?.id || '',
      }));
    }
    updateData();
  }, [deviceProfiles]);

  useEffect(() => {
    setDevicesList(
      Object.values(devices).filter(device => device.mode === DeviceMode.F_POS),
    );
  }, [devices]);

  const onPressSelect = useCallback(
    (device: Device) => {
      const name = device?.name;
      const isPaired = device.isPaired;
      if (!isPaired) {
        onSelect(device);
      } else {
        showModal(
          <ConfirmationDialog
            title={translate('dialog.deviceInUse')}
            message={translate('dialog.overrideConfirmation', { label: name })}
            confirmLabel={translate('dialog.continue')}
            onConfirm={() => {
              closeModal();
              onSelect(device);
            }}
          />,
        );
      }
    },
    [showModal, translate, closeModal, onSelect],
  );
  const onPressCreateDevice = useCallback(() => {
    setIsCreateModalVisible(true);
  }, []);

  const renderDevices = ({ item }: { item: Device; index: number }) => {
    return (
      <TouchableOpacity onPress={() => onPressSelect(item)}>
        <View style={styles.row}>
          <View style={styles.rowTextContainer}>
            <Text
              style={styles.deviceNameText}
            >{`${item.name} (${item.deviceProfile.name})`}</Text>
            {item.isPaired ? (
              <Text style={styles.deviceDetailsText}>{item.details}</Text>
            ) : (
              <Text style={styles.errorText}>
                {translate('assignRegister.deviceNotPaired')}
              </Text>
            )}
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {(!item.printingOptions || item.printingOptions?.length === 0) && (
              <Text style={styles.errorText}>
                {translate('assignRegister.noPrinters')}
              </Text>
            )}
            <Icon name={'angle-right'} color={theme.colors.black} />
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <Text style={styles.subTitleText}>
        {translate('assignRegister.selectDevice')}
      </Text>
      <View style={styles.searchContainer}>
        <Search
          testID="search"
          maxLength={50}
          onChangeText={onSearch}
          placeholder={translate('assignRegister.searchDevice')}
          containerStyle={{ flex: 1 }}
        />
        <TreatButton
          uppercase
          type={'neutralLight'}
          onPress={() => onPressCreateDevice()}
          label={translate('backOfficeDeviceModal.createBtn')}
          containerStyle={{ marginLeft: 10 }}
        />
      </View>
      {loading || loadingDevices || loadingDeviceProfiles ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size={'large'} color={theme.colors.primary} />
        </View>
      ) : (
        <FlatList
          showsVerticalScrollIndicator={false}
          style={styles.listContainer}
          data={devicesList}
          renderItem={renderDevices}
        />
      )}
      {isCreateModalVisible && (
        <Modal
          title={translate('backOfficeDeviceModal.createTitle')}
          type="positive"
          onConfirm={{ label: translate('button.createNew'), action: onSubmit }}
          onDismiss={{ action: () => setIsCreateModalVisible(false) }}
        >
          <>
            <InputText
              testID={'device-name-input'}
              title={translate('backOfficeDeviceModal.deviceName')}
              placeholder={translate('backOfficeDeviceModal.deviceName')}
              value={form.name}
              isVerified={isNameValid}
              onChangeText={onChange.bind(null, 'name')}
              maxLength={50}
              containerStyle={styles.formInputStyle}
            />
            <Picker
              title={translate('backOfficeDeviceModal.deviceProfileTitle')}
              options={deviceProfilesOptions}
              containerStyle={styles.formInputStyle}
              selectedValue={form.deviceProfile}
              onValueChange={onChange.bind(null, 'deviceProfile')}
              testID={'device-profile-input'}
            />
          </>
        </Modal>
      )}
    </>
  );
};

export default DeviceList;

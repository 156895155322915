import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import {
  AdjustmentType,
  Order,
  OrderItem,
  OrderItemStatus,
  RefundType,
  RewardAdjustment,
} from '@oolio-group/domain';
import {
  computeOrderTotals,
  flatToPercentageForAdjustment,
  limitDecimalCount,
} from '@oolio-group/order-helper';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { IMap } from '../../../../screens/BackOffice/Reports/types';
import { keyBy } from 'lodash';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import theme from '../../../../common/default-theme';
import styles from './RefundModal.styles';
import Icon from '../../../Icon/Icon';
import { CartSelectionState } from '../../Cart/Cart';
import CartItems from '../../Cart/CartItems/CartItems';
import CartTotals from '../../Cart/CartTotals/CartTotals';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

interface RefundModalProps {
  order: Order;
  onPressBtn: (
    selectedOrderItemIds: IMap<number>,
    refundOrderForSelectedProducts: Order,
    refundType: RefundType,
  ) => void;
}

const RefundModal: React.FC<RefundModalProps> = ({ order, onPressBtn }) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const orderNumber = order?.orderNumber || '';
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [refundOrderObj, setRefundOrderObj] = useState<Order>(order);
  const [totalRefund, setTotalRefund] = useState<number>(0);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [itemAndQuantityToRefund, setItemAndQuantityToRefund] = useState<
    IMap<number>
  >({});

  useEffect(() => {
    if (order.orderItems) {
      const newOrderItemsArray = [] as OrderItem[];
      order.orderItems.forEach(item => {
        if (item.status === OrderItemStatus.COMPLETED) {
          const originalQuantity = item?.quantity;
          if (originalQuantity <= 1) {
            newOrderItemsArray.push({ ...item, id: item.id + '-0' });
          } else {
            const itemCopy = { ...item };
            let remainingQuantity = originalQuantity;
            for (let i = 0; i < item.quantity; i++) {
              itemCopy.id = item.id + `-${i}`;
              newOrderItemsArray.push({
                ...itemCopy,
                quantity: Math.min(remainingQuantity, 1),
              });
              remainingQuantity = limitDecimalCount(remainingQuantity - 1);
            }
          }
        }
      });
      setOrderItems(newOrderItemsArray);
    }
  }, [order.orderItems]);

  useEffect(() => {
    const newRefundTotals = computeOrderTotals(refundOrderObj);
    setTotalRefund(newRefundTotals.totalPaymentAmount);
  }, [refundOrderObj]);

  const orderItemQuantityMaps = useMemo(() => {
    return keyBy(orderItems, 'id');
  }, [orderItems]);

  const onSelectedItemsChange = (updatedSelectedItems: string[]) => {
    const selectedOrderItems = orderItems.filter(item =>
      updatedSelectedItems.includes(item.id),
    );

    // For partial refund restricting payment surcharge, delivery fee, serviceCharge
    // and tip to flow to refund order as they are not divided on item.
    const newOrder: Order = {
      ...order,
      deliveryFee: 0,
      serviceCharge: 0,
      tip: 0,
      adjustments: (order.adjustments || [])
        .filter(adjustment => !adjustment.allowOnPaymentType)
        .map(adjustment =>
          flatToPercentageForAdjustment(adjustment, order.subTotal),
        ),
      payments: (order.payments || []).map(payment => {
        delete payment.paymentSurcharge;
        return payment;
      }),
      orderItems: selectedOrderItems,
    };

    setRefundOrderObj({
      ...newOrder,
      ...computeOrderTotals(newOrder),
    });
  };

  const updateItemsAndQuantityToBeRefunded = (
    itemId: string,
    quantity?: number,
  ) => {
    if (quantity && quantity > 0) {
      setItemAndQuantityToRefund(prev => ({
        ...prev,
        [itemId]: quantity,
      }));
    } else if (quantity === 0) {
      setItemAndQuantityToRefund(prev => {
        const prevCopy = { ...prev };
        delete prevCopy[itemId];
        return prevCopy;
      });
    } else {
      setItemAndQuantityToRefund(prev => ({
        ...prev,
        [itemId]: 1,
      }));
    }
  };

  const onSelectCartItem = (state: CartSelectionState) => {
    setSelectedItems(prev => {
      const selectingItemId = state.item;
      const updatedSelectedItems = [...prev];
      const itemIndex = prev.indexOf(selectingItemId);
      const actualItemId = state.item.split('-').slice(0, -1).join('-');
      const existingTotalQuantity = itemAndQuantityToRefund[actualItemId] || 0;
      const selectingQuantity =
        orderItemQuantityMaps[selectingItemId]?.quantity;
      if (itemIndex === -1) {
        const selectingQuantity =
          orderItemQuantityMaps[selectingItemId]?.quantity;
        updatedSelectedItems.push(state.item);
        onSelectedItemsChange(updatedSelectedItems);
        updateItemsAndQuantityToBeRefunded(
          actualItemId,
          existingTotalQuantity + selectingQuantity,
        );
        return updatedSelectedItems;
      }
      updatedSelectedItems.splice(itemIndex, 1);
      onSelectedItemsChange(updatedSelectedItems);
      updateItemsAndQuantityToBeRefunded(
        actualItemId,
        existingTotalQuantity - selectingQuantity,
      );
      return updatedSelectedItems;
    });
  };

  const onPressRefundBtn = useCallback(
    refundType => {
      analyticsService.capture('refund', {
        type: 'partial',
      });
      onPressBtn(itemAndQuantityToRefund, refundOrderObj, refundType);
    },
    [itemAndQuantityToRefund, onPressBtn, refundOrderObj],
  );

  const unselectAllItems = () => {
    setSelectedItems([]);
    setItemAndQuantityToRefund({});
    setTotalRefund(order.totalPaymentAmount);
  };

  const rewardItems = useMemo(() => {
    return (order?.adjustments || []).filter(
      adj => adj.adjustmentType == AdjustmentType.REWARD,
    ) as RewardAdjustment[];
  }, [order?.adjustments]);

  const formatOrderItems = useMemo(
    () =>
      // not show the payment status of item in refund modal
      orderItems?.map((item: OrderItem) => ({
        ...item,
        paymentStatus: undefined,
      })),
    [orderItems],
  );

  const onPressRefundAll = useCallback(() => {
    analyticsService.capture('refund', {
      type: 'all',
    });

    onPressRefundBtn(RefundType.FULL);
  }, [onPressRefundBtn]);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={closeModal}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text testID="order-number" style={styles.titleText}>
          {translate('refundOrder.headerTitle', { orderNumber })}
        </Text>
      </View>
      <View style={styles.content}>
        <View style={styles.items}>
          <CartItems
            items={formatOrderItems}
            onSelectItem={onSelectCartItem}
            selectedItemIds={selectedItems}
            rewardItems={rewardItems}
            subTotal={order?.subTotal}
          />
        </View>
        <CartTotals order={order} />
        <View style={styles.actions}>
          {!selectedItems.length ? (
            <>
              <TreatButton
                testID="refundAll-btn"
                type="neutral"
                label={translate('refundOrder.refundAll')}
                onPress={onPressRefundAll}
              />
              <TreatButton
                testID="btn-refundAmount"
                type="focus"
                label={translate('refundOrder.refundByAmount')}
                onPress={() => onPressRefundBtn(RefundType.BY_AMOUNT)}
                containerStyle={styles.btnGap}
              />
            </>
          ) : (
            <>
              <TreatButton
                testID="btn-partialRefund"
                type="positive"
                onPress={() => onPressRefundBtn(RefundType.By_PRODUCT)}
                label={translate('refundOrder.refundItem', {
                  itemCount: selectedItems.length,
                  amount: formatCurrency(totalRefund),
                })}
              />
              <TreatButton
                testID="unselect-all-btn"
                label={translate('refundOrder.unselectAllButton')}
                type="neutralLight"
                onPress={unselectAllItems}
                containerStyle={styles.btnGap}
              />
            </>
          )}
        </View>
      </View>
    </View>
  );
};

export default RefundModal;

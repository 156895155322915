import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  rowLight: {
    width: 4,
    height: 28,
    marginRight: 8,
    borderRadius: 2,
    backgroundColor: theme.colors.red,
  },
  cellName: {
    width: 200,
    marginRight: 10,
  },
  cellType: {
    flex: 1,
  },
  cellOrderType: {
    width: 240,
    marginRight: 'auto',
  },
  cellDefault: {
    marginRight: 15,
  },
  headerCount: {
    width: 80,
    marginRight: 22,
    textAlign: 'center',
  },
  headerDefault: {
    width: 50,
    marginLeft: 'auto',
  },
  count: {
    minWidth: 80,
    paddingVertical: 10,
    paddingHorizontal: 16,
    textAlign: 'center',
    borderRadius: theme.radius.s,
    fontFamily: theme.fonts.medium,
    backgroundColor: theme.colors.grey1,
  },
});

export default styles;

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { OrderType, UpdateSalesChannel } from '@oolio-group/domain';
import {
  GET_ORDER_TYPES_SALES_CHANNELS_QUERY,
  UPDATE_ORDER_TYPES_AND_SALES_CHANNEL,
} from '../../../../graphql/settings';
import { useNotification } from '../../../../hooks/Notification';
import { parseApolloError, noopHandler } from '../../../../utils/errorHandlers';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { stripProperties } from '../../../../utils/stripObjectProps';
import keyBy from 'lodash/keyBy';
import { useSession } from '../../../../hooks/app/useSession';
import { SalesChannelsSection } from './Sections/SalesChannelSection';
import { OrderTypesSection } from './Sections/OrderTypesSection';
import { ScreenLayout } from '../../../../components/Office/ScreenLayout/ScreenLayout';

interface SalesChannel {
  id: string;
  name: string;
  isActive: boolean;
  integrationStatus: string;
  orderTypes?: OrderType[];
}

export const SalesChannelScreen: React.FC = () => {
  const { showNotification } = useNotification();
  const [session, setSession] = useSession();
  const [channelsDictionary, setChannelsDictionary] = useState(
    {} as { [key: string]: SalesChannel },
  );
  const [orderTypesDictionary, setOrderTypeDictionary] = useState(
    {} as { [key: string]: OrderType },
  );

  const orderTypesData = useMemo(() => {
    return Object.values(orderTypesDictionary);
  }, [orderTypesDictionary]);

  const channelsData = useMemo(() => {
    return Object.values(channelsDictionary);
  }, [channelsDictionary]);

  const [updateOrderAndSales, updateOperation] = useMutation(
    UPDATE_ORDER_TYPES_AND_SALES_CHANNEL,
    {
      onError: noopHandler,
    },
  );

  const orderAndSalesChannelDetails = useQuery(
    GET_ORDER_TYPES_SALES_CHANNELS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect((): void => {
    if (orderAndSalesChannelDetails.error) {
      showNotification({
        error: true,
        message: parseApolloError(orderAndSalesChannelDetails.error),
      });
    }
  }, [orderAndSalesChannelDetails.error, showNotification]);

  useEffect((): void => {
    if (orderAndSalesChannelDetails.data) {
      setChannelsDictionary(
        keyBy(orderAndSalesChannelDetails.data.salesChannels, 'id'),
      );
      setOrderTypeDictionary(
        keyBy(orderAndSalesChannelDetails.data.orderTypes, 'id'),
      );
    }
  }, [orderAndSalesChannelDetails.data, showNotification]);

  useEffect((): void => {
    if (updateOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(updateOperation.error),
      });
    }
  }, [updateOperation.error, showNotification]);

  useEffect((): void => {
    if (updateOperation.data) {
      showNotification({
        success: true,
        message: 'Successfully updated',
      });
    }
  }, [updateOperation.data, showNotification]);

  const onChangeOrderType = useCallback(
    (id, prop, value): void => {
      const ordersData = {
        ...orderTypesDictionary,
      };
      ordersData[id] = {
        ...ordersData[id],
        [prop]: value,
      };
      setOrderTypeDictionary(ordersData);
    },
    [orderTypesDictionary],
  );

  const onChangeSalesChannel = useCallback(
    (id, prop, value): void => {
      if (prop === 'orderTypes') {
        value = (value as string[]).map((x): { id: string } => ({ id: x }));
      }
      const channelsData = { ...channelsDictionary };
      channelsData[id] = { ...channelsData[id], [prop]: value };
      setChannelsDictionary(channelsData);
    },
    [channelsDictionary],
  );

  const onPressSave = useCallback(() => {
    if (channelsData.length) {
      const orderTypesDataTemp = stripProperties(orderTypesData, '__typename');
      const salesChannelsDataTemp = stripProperties(channelsData, '__typename');
      updateOrderAndSales({
        variables: {
          inputOrder: orderTypesDataTemp as OrderType[],
          inputSales: (salesChannelsDataTemp as SalesChannel[]).map(
            channel => ({
              id: channel.id,
              name: channel.name,
              isActive: channel.isActive,
              orderTypes: (channel?.orderTypes || []).map(x => x.id),
            }),
          ) as UpdateSalesChannel[],
        },
      });
      if (session.deviceProfile) {
        let setDefaultOrderTypeNull = false;
        if (
          !orderTypesDataTemp.find(
            (orderType: OrderType) =>
              orderType.id == session?.deviceProfile?.defaultOrderType?.id,
          )
        ) {
          setDefaultOrderTypeNull = true;
        }
        setSession({
          ...session,
          deviceProfile: {
            ...session.deviceProfile,
            orderTypes: orderTypesDataTemp,
            defaultOrderType: setDefaultOrderTypeNull
              ? undefined
              : session.deviceProfile?.defaultOrderType,
          },
        });
      }
    }
  }, [channelsData, orderTypesData, updateOrderAndSales, setSession, session]);

  const onDeleteSalesChannel = useCallback(
    (id: string): void => {
      const channelsData = { ...channelsDictionary };
      if (channelsData[id]) {
        delete channelsData[id];
        setChannelsDictionary(channelsData);
      }
    },
    [channelsDictionary],
  );

  const onAddSalesChannel = useCallback(
    (salesChannel: SalesChannel): void => {
      const channelsData = { ...channelsDictionary };
      channelsData[salesChannel.id] = salesChannel;
      setChannelsDictionary(channelsData);
    },
    [channelsDictionary],
  );

  const onDeleteOrderType = useCallback(
    (id: string): void => {
      const ordersData = { ...orderTypesDictionary };
      if (ordersData[id]) {
        delete ordersData[id];
        setOrderTypeDictionary(ordersData);
      }
    },
    [orderTypesDictionary],
  );

  const onAddOrderType = useCallback(
    (orderType: OrderType): void => {
      const ordersData = { ...orderTypesDictionary };
      ordersData[orderType.id] = orderType;
      setOrderTypeDictionary(ordersData);
    },
    [orderTypesDictionary],
  );

  return (
    <ScreenLayout title="Sales Channels | Oolio" onSave={onPressSave}>
      <SalesChannelsSection
        salesChannelsData={channelsData}
        onChange={onChangeSalesChannel}
        orderTypesData={orderTypesData}
        onDeleteSalesChannel={onDeleteSalesChannel}
        onAddSalesChannel={onAddSalesChannel}
      />
      <OrderTypesSection
        orderTypesData={orderTypesData}
        onChange={onChangeOrderType}
        onDeleteOrderType={onDeleteOrderType}
        onAddOrderType={onAddOrderType}
      />
    </ScreenLayout>
  );
};

import { Table } from '@oolio-group/domain';
import { BehaviorSubject } from 'rxjs';

export const sectionStatsVisibilityController = new BehaviorSubject<boolean>(
  false,
);

export const tableStatsVisibilityController = new BehaviorSubject<boolean>(
  false,
);

export const tableSelectionController = new BehaviorSubject<Table | null>(null);
export const orderIdSelectionController = new BehaviorSubject<string>('');

export const sectionIdController = new BehaviorSubject<string>('');

export enum TableAction {
  DEFAULT = 'DEFAULT',
  SHOW_TABLE_STATS = 'SHOW_TABLE_STATS',
  PRINT = 'PRINT',
  PAY = 'PAY',
  TRANSFER = 'TRANSFER',
  MERGE = 'MERGE',
}

export const tableActionController = new BehaviorSubject<TableAction>(
  TableAction.DEFAULT,
);

export const transferSelectionsController = new BehaviorSubject<{
  fromTable?: Table;
  fromOrderIndex?: number;
  toTable?: Table;
}>({});
export const mergeTableSelectionsController = new BehaviorSubject<{
  fromTable?: Table;
  toTable?: Table;
}>({});

export const unsavedTableController = new BehaviorSubject<Table[]>([]);
export const editModeController = new BehaviorSubject<boolean>(false);

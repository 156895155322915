import { RenderProps, StyleFn, Table } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import React, { useCallback, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import { ButtonProps } from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import TitleBar from '../../TitleBar/TitleBar';
import Modal from '../Modal';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * 1.25,
});

const numberInputStyle: StyleFn = () => ({
  flex: 1,
});

const containerStyle: StyleFn = () => ({
  width: 320,
  height: 432,
  alignSelf: 'center',
  paddingVertical: 0,
  paddingHorizontal: 20,
});
const extraRightFlank: StyleFn = () => ({
  justifyContent: 'center',
  right: 10,
});
const headerStyle: StyleFn = () => ({
  width: 320,
  alignSelf: 'center',
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  cartItemFullWidth: {
    width: '100%',
    bottom: 10,
  },
  keypad: {
    flex: 0.7,
  },
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const inputDisplayStyle: StyleFn = () => ({
  height: 80,
});

const backspaceButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.transparent,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

interface SetGuestModalProps {
  onSubmit: (guestCount: number, table?: Table) => void;
  closeModal: () => void;
  table?: Table;
}

function SetGuestModal({ closeModal, onSubmit, table }: SetGuestModalProps) {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [guestCount, setGuestCount] = useState<string>('0');
  const onPressKeyNumber = useCallback(
    (value: string) => {
      if (guestCount.length >= MAX_KEYPAD_INPUT) return;
      setGuestCount(currentGuestCount =>
        currentGuestCount === '0' ? value : currentGuestCount + value,
      );
    },
    [guestCount.length],
  );
  const onPressBackspace = useCallback(() => {
    setGuestCount(currentGuestCount =>
      currentGuestCount === '0' || currentGuestCount.length === 1
        ? '0'
        : currentGuestCount.slice(0, currentGuestCount.length - 1),
    );
  }, []);

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={translate('setGuestModal.title')}
        titleLeft={<CloseButtons onPress={closeModal} />}
      />
      <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
        <InputDisplay
          value={guestCount}
          right={
            <IconButton
              icon="backspace"
              iconSize={22}
              iconColor={theme.colors.white}
              containerSize={44}
              onPress={onPressBackspace}
              containerStyle={css(backspaceButtonStyle)}
            />
          }
          extraRightFlank={css(extraRightFlank)}
          containerStyle={css(inputDisplayStyle)}
        />
        <View style={[css(keypadStyle), styles.fullWidth]}>
          <NumberInput
            onPressKey={onPressKeyNumber}
            containerStyle={css(numberInputStyle)}
            keyHeight={70}
            keyWidth={91}
            twistKeyWidth={2.05}
            onSubmit={() => onSubmit(parseInt(guestCount, 10), table)}
          />
        </View>
      </Modal>
    </View>
  );
}

export default SetGuestModal;

import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { VoidReason, Order } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './VoidOrder.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import Sticker from '../../../Shared/Sticker/Sticker';
import TreatPicker from '../../../Shared/Select/Picker';
import InputText from '../../../Shared/Inputs/InputText';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

export interface VoidOrderProps {
  order: Order;
  onCancel?: (reason: VoidReason, description: string, order: Order) => void;
}

export const VoidOrderModal: React.FC<VoidOrderProps> = ({
  order,
  onCancel,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [description, setDescription] = useState('');
  const [reason, setReason] = useState(VoidReason.OTHER);

  const voidReasons = useMemo(
    () =>
      Object.values(VoidReason).map(value => ({
        value,
        label: translate(`enums.${value}`),
      })),
    [translate],
  );

  const onConfirmVoid = useCallback(() => {
    onCancel && onCancel(reason, description, order);
    closeModal();
  }, [onCancel, reason, description, order, closeModal]);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={closeModal}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>{translate('order.voidOrder')}</Text>
      </View>
      {order ? (
        <View style={styles.context}>
          <Sticker
            type="teal"
            testID="sticker-info"
            label={order.table ? order.table.name : order.orderType.code}
          />
          <Text style={styles.orderNo}>{order.orderNumber}</Text>
        </View>
      ) : null}
      <View style={styles.content}>
        <TreatPicker
          testID="select-reason"
          options={voidReasons}
          selectedValue={reason}
          onValueChange={setReason}
          title={translate('order.voidReasonLabel')}
        />
        {reason === VoidReason.OTHER ? (
          <InputText
            multiline
            testID="input-reason"
            value={description}
            onChangeText={setDescription}
            placeholder={translate('order.voidReasonPlaceholder')}
            containerStyle={styles.inputCustom}
          />
        ) : null}
        <TreatButton
          testID="btn-confirm"
          type="negative"
          label={translate('order.voidOrder')}
          onPress={onConfirmVoid}
          containerStyle={styles.btnConfirm}
        />
      </View>
    </View>
  );
};

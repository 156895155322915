import React, { useEffect, useCallback } from 'react';
import { View, Text } from 'react-native';
import { OrderType, OrderTypeCode } from '@oolio-group/domain';
import { DELETE_ORDER_TYPE } from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { CreateOrderTypeModal } from '../Modals/CreateOrderTypeModal';
import styles from '../SalesChannels.styles';
import theme from '../../../../../common/default-theme';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface OrderTypesRowProps {
  orderType: OrderType;
  onDeleteOrderType: (id: string, name: string) => void;
  onChange: (id: string, prop: string, value: string) => void;
}

interface DeleteModalProps {
  id: string;
  name: string;
  onDeleteOrderType: (id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  onDeleteOrderType,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteOrderType, deleteRequest] = useMutation(DELETE_ORDER_TYPE, {
    onError: noopHandler,
  });

  useEffect((): void => {
    if (deleteRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(deleteRequest.error),
      });
    }
  }, [deleteRequest.error, showNotification]);

  useEffect((): void => {
    if (deleteRequest.data) {
      onDeleteOrderType(id);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.orderTypeDeleted'),
      });
    }
  }, [
    deleteRequest.data,
    showNotification,
    closeModal,
    name,
    onDeleteOrderType,
    id,
    translate,
  ]);

  const onPressDelete = useCallback((): void => {
    deleteOrderType({ variables: { id: id } });
  }, [deleteOrderType, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.yes')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onPressDelete}
    />
  );
};

export const OrderTypesRow: React.FC<OrderTypesRowProps> = ({
  orderType,
  onDeleteOrderType,
  onChange,
}: OrderTypesRowProps) => {
  const { translate } = useTranslation();
  const isPreDefinedOrderType = Object.values(OrderTypeCode).includes(
    orderType.code as OrderTypeCode,
  );

  return (
    <View testID="row-type" style={theme.tables.row}>
      <InputText
        testID="input-code"
        value={orderType.code}
        editable={!isPreDefinedOrderType}
        placeholder={translate('backOfficeSettings.code')}
        onChangeText={
          !isPreDefinedOrderType
            ? onChange.bind(null, orderType.id, 'code')
            : undefined
        }
        maxLength={4}
        alignText="center"
        containerStyle={styles.cellCode}
      />
      <InputText
        testID="input-name"
        value={orderType.name}
        editable={!isPreDefinedOrderType}
        placeholder={translate('backOfficeSettings.OrderTypeName')}
        onChangeText={
          !isPreDefinedOrderType
            ? onChange.bind(null, orderType.id, 'name')
            : undefined
        }
        containerStyle={styles.cellName}
      />
      <ButtonIcon
        testID="btn-deleteType"
        icon="trash-alt"
        disabled={isPreDefinedOrderType}
        type={'negativeLight'}
        onPress={(): void => onDeleteOrderType(orderType.id, orderType.name)}
      />
    </View>
  );
};

interface OrderTypesSectionProps {
  orderTypesData: OrderType[];
  onChange: (id: string, prop: string, value: string) => void;
  onDeleteOrderType: (id: string) => void;
  onAddOrderType: (orderType: OrderType) => void;
}

export const OrderTypesSection: React.FC<OrderTypesSectionProps> = ({
  orderTypesData,
  onChange,
  onDeleteOrderType,
  onAddOrderType,
}: OrderTypesSectionProps) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const OnPressCreate = useCallback((): void => {
    showModal(<CreateOrderTypeModal onAddOrderType={onAddOrderType} />);
  }, [showModal, onAddOrderType]);

  const onPressDelete = useCallback(
    (id: string, name: string): void => {
      showModal(
        <DeleteModal
          id={id}
          name={name}
          onDeleteOrderType={onDeleteOrderType}
        />,
      );
    },
    [showModal, onDeleteOrderType],
  );

  const sortedOrderTypes = () => {
    const predefinedTypes: OrderType[] = [];
    const customTypes: OrderType[] = [];

    orderTypesData.forEach(orderType => {
      const isPredefined = Object.values(OrderTypeCode).includes(
        orderType.code as OrderTypeCode,
      );

      if (isPredefined) {
        predefinedTypes.push(orderType);
      } else {
        customTypes.push(orderType);
      }
    });

    predefinedTypes.sort((a, b) => a.code.localeCompare(b.code));
    customTypes.sort((a, b) => a.code.localeCompare(b.code));

    return [...predefinedTypes, ...customTypes];
  };

  return (
    <Section
      title={translate('backOfficeSettings.OrderTypes')}
      layoutWidth="medium"
    >
      <View style={styles.tableContainer}>
        <View style={theme.tables.header}>
          <Text style={[theme.tables.headerText, styles.headerCode]}>
            {translate('backOfficeSettings.code')}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerName]}>
            {translate('backOfficeSettings.OrderTypeName')}
          </Text>
        </View>
        <View style={styles.tableBody}>
          {sortedOrderTypes().map((type: OrderType, i: number) => (
            <OrderTypesRow
              key={i}
              orderType={type}
              onDeleteOrderType={onPressDelete}
              onChange={onChange}
            />
          ))}
        </View>
        <CreateButton onPress={OnPressCreate} />
      </View>
    </Section>
  );
};

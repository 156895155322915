import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 16,
    paddingHorizontal: 20,
    backgroundColor: theme.colors.red,
    zIndex: 998,
  },
  errorText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  errorCount: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    marginHorizontal: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.black,
  },
  // eslint-disable-next-line react-native/no-color-literals
  mdlBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
  },
  mdlBody: {
    width: 340,
    padding: 40,
    paddingTop: 50,
    alignItems: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  mdlText: {
    marginTop: 12,
    marginBottom: 40,
  },
  mdlTitle: {
    fontSize: 20,
    lineHeight: 28,
    marginBottom: 20,
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
    textAlign: 'center',
  },
  mdlSubtitle: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
    textAlign: 'center',
  },
  btnDismiss: {
    width: 280,
  },
});

export default styles;

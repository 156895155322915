import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  DeviceProfile,
  OnboardingAction,
  OnboardingSection,
  OnboardingArea,
  OnboardingCheckList,
  NO_DATA,
  CreateDeviceProfileInput,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';
import {
  useNavigation,
  useRoute,
  useIsFocused,
  useFocusEffect,
} from '@react-navigation/native';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';
import { useOnboarding } from '../../../../../hooks/app/useOnboarding';
import {
  DEVICE_MODE_COLOR_MAPPING,
  DEVICE_MODE_MAPPING,
} from '../../../../../types/Common';
import { CreateDeviceProfileModal } from './CreateDeviceProfile/CreateDeviceProfileModal';
import styles from './DeviceProfiles.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../../components/Shared/Search/Search';

interface DeviceProfileRowProps {
  deviceProfile: DeviceProfile;
  venueId: string;
  storeId: string;
  onChangeDefault: (id: string) => void;
}

export const DeviceProfilesRow: React.FC<DeviceProfileRowProps> = ({
  deviceProfile,
  storeId,
  venueId,
}: DeviceProfileRowProps) => {
  const navigation = useNavigation();
  const activeDevices =
    deviceProfile.devices?.filter(device => device.isPaired) || [];
  return (
    <TouchableOpacity
      testID="row-profile"
      style={theme.tables.row}
      onPress={(): void => {
        deviceProfile.id &&
          navigation.navigate('DeviceProfileSettings', {
            deviceProfileId: deviceProfile.id,
            storeId,
            venueId,
          });
      }}
    >
      <View
        testID="mode-color"
        style={[
          styles.rowLight,
          // eslint-disable-next-line react-native/no-inline-styles, react-native/no-color-literals
          {
            backgroundColor: deviceProfile.mode
              ? DEVICE_MODE_COLOR_MAPPING[deviceProfile.mode]
              : '',
          },
        ]}
      />
      <Text testID="profile-name" style={styles.cellName}>
        {deviceProfile.name}
      </Text>
      <Text testID="profile-type" style={styles.cellType}>
        {deviceProfile.mode ? DEVICE_MODE_MAPPING[deviceProfile.mode] : NO_DATA}
      </Text>
      <Text style={styles.count}>{activeDevices?.length || 0}</Text>
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey5} />
      </View>
    </TouchableOpacity>
  );
};

const getDeviceProfilesBySearchQuery = (
  deviceProfiles: DeviceProfile[],
  searchQuery: string,
): DeviceProfile[] => {
  return deviceProfiles.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
};

type FormState = Record<string, DeviceProfile & { isChanged: boolean }>;

export const DeviceProfiles: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormState>({});
  const isFocused = useIsFocused();
  const { showModal, closeModal } = useModal();
  const [searchString, setSearchString] = useState('');

  const route = useRoute();
  const { updateOnboardingStatus } = useOnboarding();

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const storeId = params.storeId || '';
  const venueId = params.venueId || '';

  const {
    loading,
    deviceProfiles,
    createdDeviceProfileId,
    getAllDeviceProfiles,
    // updateDeviceProfiles,
    createDeviceProfile,
    updatedDeviceProfileIds,
    error: deviceProfileError,
  } = useDeviceProfiles({ storeId, venueId });

  useFocusEffect(
    useCallback(() => {
      getAllDeviceProfiles();
    }, [getAllDeviceProfiles]),
  );

  useEffect(() => {
    isFocused && getAllDeviceProfiles();
  }, [getAllDeviceProfiles, isFocused]);

  useEffect(() => {
    updateOnboardingStatus(
      OnboardingArea.SETTINGS,
      OnboardingSection.VIEW_STORE_AND_REGISTER_SETUP,
      OnboardingCheckList.STORES_REGISTER_PROFILES,
      OnboardingAction.READ,
    );
  }, [updateOnboardingStatus]);

  useEffect(() => {
    if (deviceProfiles) {
      setForm(deviceProfiles as FormState);
    }
  }, [deviceProfiles]);

  useEffect((): void => {
    if (deviceProfileError) {
      showNotification({
        error: true,
        message: deviceProfileError,
      });
    }
  }, [deviceProfileError, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileIds && updatedDeviceProfileIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileIds, showNotification, translate]);

  const onPressCreateDeviceProfile = useCallback(
    (newProfile: CreateDeviceProfileInput) => {
      createDeviceProfile(newProfile);
      closeModal();
    },
    [closeModal, createDeviceProfile],
  );

  const onPressCreateNew = useCallback(() => {
    showModal(
      <CreateDeviceProfileModal onCreate={onPressCreateDeviceProfile} />,
    );
  }, [onPressCreateDeviceProfile, showModal]);

  // const onPressSave = useCallback((): void => {
  //   // update deviceProfiles
  //   const data = Object.values(form)
  //     .filter(deviceProfile => deviceProfile.isChanged)
  //     .map(deviceProfile =>
  //       pick(deviceProfile, ['id', 'name', 'isDefault']),
  //     ) as unknown as UpdateDeviceProfileInput[];

  //   if (data.length > 0) {
  //     if (data.some(deviceProfile => !deviceProfile.name)) {
  //       showNotification({
  //         error: true,
  //         message: translate('backOfficeDeviceProfiles.fieldsMissing'),
  //       });
  //       return;
  //     } else {
  //       updateDeviceProfiles(data);
  //     }
  //   }
  // }, [showNotification, translate, updateDeviceProfiles, form]);

  // const onChangeName = useCallback((id: string, value: string): void => {
  //   setForm(form => ({
  //     ...form,
  //     [id]: {
  //       ...form[id],
  //       name: value,
  //       isChanged: true,
  //     },
  //   }));
  // }, []);

  useEffect(() => {
    if (createdDeviceProfileId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyCreated'),
      });
    }
  }, [createdDeviceProfileId, showNotification, translate]);

  const onChangeDefault = useCallback(
    (id: string): void => {
      const existingDefaultRegister = Object.values(form).find(
        r => r.isDefault && r.id !== id,
      );
      if (existingDefaultRegister) {
        setForm(form => ({
          ...form,
          [existingDefaultRegister.id]: {
            ...form[existingDefaultRegister.id],
            isDefault: false,
            isChanged: true,
          },
        }));
      }

      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          isDefault: true,
          isChanged: true,
        },
      }));
    },
    [form],
  );

  const filteredDeviceProfiles = useMemo(() => {
    let deviceProfiles: DeviceProfile[] = Object.values(form);
    if (searchString) {
      deviceProfiles = getDeviceProfilesBySearchQuery(
        deviceProfiles,
        searchString,
      );
    }
    return deviceProfiles;
  }, [form, searchString]);

  return (
    <ScreenLayout hideFooter title="Device Profiles | Oolio" loading={loading}>
      <Section
        title={translate('backOfficeDeviceProfiles.title')}
        subtitle={translate('backOfficeDeviceProfiles.subtitle')}
        layoutWidth="medium"
      >
        <View style={styles.filtersContainer}>
          <Search
            testID="search-profiles"
            placeholder={translate(
              'backOfficeDeviceProfiles.searchPlaceholder',
            )}
            onChangeText={setSearchString}
            containerStyle={styles.search}
          />
          <CreateButton onPress={onPressCreateNew} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellName]}>
              {translate('backOfficeDeviceProfiles.profileName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellType]}>
              {translate('backOfficeDeviceProfiles.profileType')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerCount]}>
              {translate('backOfficeSettings.devices')}
            </Text>
          </View>
          <View>
            {filteredDeviceProfiles.map((profile: DeviceProfile, i: number) => (
              <DeviceProfilesRow
                key={i}
                deviceProfile={profile}
                storeId={storeId}
                venueId={venueId}
                onChangeDefault={onChangeDefault}
              />
            ))}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};

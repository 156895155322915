/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useNotification } from '../../../../../hooks/Notification';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useDevices } from '../../../../../hooks/app/useDevices';
import { useSession } from '../../../../../hooks/app/useSession';
import { CreateDeviceInput, Device, DeviceMode } from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';
import styles from './Devices.styles';
import theme from '../../../../../common/default-theme';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import Search from '../../../../../components/Shared/Search/Search';
import DevicesRow from './TableRow/DevicesRow';
import { CreateDeviceModal } from './CreateDevice/CreateDeviceModal';

export interface DeviceData extends Device {
  isCurrent?: boolean;
}

export const DevicesScreen: React.FC = () => {
  const route = useRoute();
  const [session] = useSession();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const isFocused = useIsFocused();

  const [searchString, setSearchString] = useState('');
  const [selectedType, setFilteredType] = useState<DeviceMode>(
    '' as DeviceMode,
  );

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const { deviceProfiles, getAllDeviceProfiles } = useDeviceProfiles({
    storeId: params?.storeId,
    venueId: params?.venueId,
  });

  useEffect(() => {
    getAllDeviceProfiles();
  }, [getAllDeviceProfiles]);

  const onCreateDeviceCompleted = useCallback(
    deviceId => {
      showNotification({
        success: true,
        message: translate('backOfficeDeviceModal.successfullySaved'),
      });
      closeModal();
      setSearchString('');
      navigation.navigate('DeviceSettings', {
        storeId: params.storeId,
        deviceId: deviceId,
      });
    },
    [closeModal, navigation, params.storeId, showNotification, translate],
  );

  const { devices, createDevice, getDevices, loading, error } = useDevices({
    storeId: params?.storeId,
    onCreateDeviceCompleted,
  });

  const onPressCreateDevice = useCallback(
    (newDevice: CreateDeviceInput) => {
      createDevice(newDevice);
    },
    [createDevice],
  );

  useEffect(() => {
    if (params?.storeId && isFocused) {
      getDevices();
    }
  }, [getDevices, isFocused, params?.storeId]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const devicesData = useMemo(
    () =>
      Object.values(devices).map(device => ({
        ...device,
        isCurrent: session.device && session.device.id === device.id,
      })),
    [devices, session],
  );

  const getDevicesBySearchQuery = (
    devices: Device[],
    searchQuery: string,
  ): Device[] => {
    return devices.filter(device =>
      device.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const getDevicesByTypes = (devices: Device[], mode: DeviceMode): Device[] => {
    return devices.filter(device => device.mode === mode);
  };

  const filteredDevices = useMemo(() => {
    let allDevices: DeviceData[] = devicesData;

    if (searchString) {
      allDevices = getDevicesBySearchQuery(allDevices, searchString);
    }

    if (selectedType) {
      allDevices = getDevicesByTypes(allDevices, selectedType);
    }

    const sortedDevices = allDevices.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sortedDevices;
  }, [devicesData, searchString, selectedType]);

  const deviceTypes = [
    {
      label: translate('backOfficeDevices.allTypes'),
      value: '',
    },
    {
      label: translate('backOfficeDeviceModal.posApp'),
      value: DeviceMode.F_POS,
    },
    {
      label: translate('backOfficeDeviceModal.mPos'),
      value: DeviceMode.M_POS,
    },
    {
      label: translate('backOfficeDeviceModal.kiosk'),
      value: DeviceMode.KIOSK,
    },
  ];

  const onPressCreateNew = useCallback(() => {
    showModal(
      <CreateDeviceModal
        onCreate={onPressCreateDevice}
        storeId={params?.storeId}
        venueId={params?.venueId}
        deviceProfiles={Object.values(deviceProfiles)}
      />,
    );
  }, [
    deviceProfiles,
    onPressCreateDevice,
    params?.storeId,
    params?.venueId,
    showModal,
  ]);

  const currentDevice = filteredDevices.find(row => row.isCurrent === true);

  return (
    <ScreenLayout loading={loading} title="Devices | Oolio" hideFooter>
      <Section layoutWidth="large">
        <View style={styles.filtersContainer}>
          <TreatPicker
            testID="select-device-type"
            options={deviceTypes}
            selectedValue={selectedType}
            onValueChange={setFilteredType}
            containerStyle={styles.dropdownContainer}
          />
          <Search
            testID="search-device"
            onChangeText={setSearchString}
            placeholder={translate('backOfficeDevices.searchPlaceholder')}
            containerStyle={styles.searchContainer}
          />
          <CreateButton onPress={onPressCreateNew} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, { width: 300 }]}>
              {translate('backOfficeDevices.deviceName')}
            </Text>
            <Text style={[theme.tables.headerText, { width: 80 }]}>
              {translate('backOfficeDevices.mode')}
            </Text>
            <Text style={[theme.tables.headerText, { width: 100 }]}>
              {translate('backOfficeDevices.profile')}
            </Text>
            <Text style={[theme.tables.headerText, { flex: 1 }]}>
              {translate('backOfficeDevices.appVersion')}
            </Text>
            <Text style={[theme.tables.headerText, { width: 100 }]}>
              {translate('backOfficeDevices.status')}
            </Text>
          </View>
          <View>
            {currentDevice && (
              <DevicesRow
                device={currentDevice}
                storeId={params?.storeId}
                key={currentDevice.id}
              />
            )}
            {filteredDevices
              .filter(device => device.isCurrent !== true)
              .map((device: DeviceData, i: number) => (
                <DevicesRow
                  device={device}
                  storeId={params?.storeId}
                  key={`${i}-${device.id}`}
                />
              ))}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default DevicesScreen;

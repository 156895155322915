import { useMemo } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { parseApolloError } from '../../utils/errorHandlers';
import { GET_USER_DETAILS } from '../../graphql/users';
import { User } from '@oolio-group/domain';
import { useSession } from '../../hooks/app/useSession';

export interface UseUserProps {
  loading: boolean;
  error?: string;
  user: User | undefined;
  refetchUser: () => void;
  networkStatus: number;
}

export function useUser(): UseUserProps {
  const [session] = useSession();

  const { data, loading, error, refetch, networkStatus } = useQuery(
    GET_USER_DETAILS,
    {
      variables: { id: session?.user?.id },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  return useMemo(
    () => ({
      user: data?.user,
      loading,
      error: error ? parseApolloError(error) : undefined,
      refetchUser: refetch,
      networkStatus,
    }),
    [data, loading, error, refetch, networkStatus],
  );
}

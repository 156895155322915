import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  ImageUploadInput,
  PrinterProfileType,
  UpdatePrinterTemplateInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../../common/default-theme';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import Section from '../../../../../components/Office/Section/Section';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import { useNotification } from '../../../../../hooks/Notification';
import { usePrinterTemplates } from '../../../../../hooks/app/usePrinterTemplates';
import { isWithinCharLimit } from '../../../../../utils/validator';
import { HEADER_FOOTER_CHAR_LIMIT } from '../../../../../constants';

interface FormData {
  headerText?: string;
  footerText?: string;
  socialLinks: {
    facebook?: string;
    instagram?: string;
    twitter?: string;
    website?: string;
  };
  oolioBranding: boolean;
  logoURL?: string;
  imageRawData?: ImageUploadInput;
}

const defaultBrandingState = true;

const initialFormState: FormData = {
  headerText: '',
  footerText: '',
  socialLinks: {
    facebook: '',
    instagram: '',
    twitter: '',
    website: '',
  },
  oolioBranding: defaultBrandingState,
  logoURL: '',
  imageRawData: undefined,
};

export const ReceiptTemplate: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [form, setForm] = useState<FormData>(initialFormState);

  const {
    printerTemplates,
    getPrinterTemplates,
    updatePrinterTemplate,
    error,
    loading,
  } = usePrinterTemplates();

  useEffect(() => {
    getPrinterTemplates();
  }, [getPrinterTemplates]);

  const billingReceipt = printerTemplates.find(
    printerTemplate =>
      printerTemplate.templateType === PrinterProfileType.BILLING,
  );

  useEffect((): void => {
    if (billingReceipt) {
      setForm({
        logoURL: billingReceipt.logoURL || '',
        headerText: billingReceipt.headerText || '',
        footerText: billingReceipt.footerText || '',
        socialLinks: {
          facebook: billingReceipt.socialLinks?.facebook || '',
          instagram: billingReceipt.socialLinks?.instagram || '',
          twitter: billingReceipt.socialLinks?.twitter || '',
          website: billingReceipt.socialLinks?.website || '',
        },
        oolioBranding: Boolean(billingReceipt.oolioBranding),
      });
    }
  }, [billingReceipt]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChange = useCallback(
    (prop: string, value: string | boolean | ImageUploadInput): void => {
      setHasChanges(true);
      setForm(form => {
        // Check if the prop is one of the social links
        if (['facebook', 'instagram', 'twitter', 'website'].includes(prop)) {
          return {
            ...form,
            socialLinks: {
              ...form.socialLinks,
              [prop]: value,
            },
          };
        }
        return {
          ...form,
          [prop]: value,
        };
      });
    },
    [],
  );

  const formValidationStatus: Record<string, boolean> = {
    headerText: isWithinCharLimit(
      form.headerText || '',
      undefined,
      HEADER_FOOTER_CHAR_LIMIT,
    ),
    footerText: isWithinCharLimit(
      form.footerText || '',
      undefined,
      HEADER_FOOTER_CHAR_LIMIT,
    ),
  };

  const isFormInvalid = Object.values(formValidationStatus).some(
    value => value == false,
  );

  const onPressSave = async (): Promise<void> => {
    if (billingReceipt && !isFormInvalid) {
      const payload: UpdatePrinterTemplateInput = {
        template: billingReceipt.template,
        id: billingReceipt.id,
        name: billingReceipt.name,
        headerText: form.headerText,
        footerText: form.footerText,
        socialLinks: {
          facebook: form.socialLinks.facebook,
          instagram: form.socialLinks.instagram,
          twitter: form.socialLinks.twitter,
          website: form.socialLinks.website,
        },
        oolioBranding: form.oolioBranding,
        ...(form.imageRawData && {
          imageRawData: {
            base64: form.imageRawData.base64,
            name: form.imageRawData.name,
            type: form.imageRawData.type,
          },
        }),
      };

      const status = await updatePrinterTemplate(payload);

      if (status) {
        showNotification({
          success: true,
          message: translate('backOfficeReceiptTemplate.updateTemplateSuccess'),
        });
        setHasChanges(false);
      } else if (error) {
        showNotification({
          error: true,
          message: translate('backOfficeReceiptTemplate.updateTemplateFail'),
        });
      }
    }
    if (isFormInvalid) {
      showNotification({
        error: true,
        message: translate('form.formValidationFail'),
      });
    }
  };

  return (
    <ScreenLayout
      loading={loading}
      onSave={onPressSave}
      hideFooter={loading || !hasChanges}
      title={translate('backOfficeReceiptTemplate.receiptTemplates')}
    >
      <Section title={translate('backOfficeReceiptTemplate.detailsHeading')}>
        <View style={theme.forms.row}>
          <InputText
            multiline
            testID="input-headerText"
            title={translate('backOfficeReceiptTemplate.headerText')}
            value={form.headerText || ''}
            placeholder={translate(
              'backOfficeReceiptTemplate.placeholderInputText',
            )}
            onChangeText={onChange.bind(null, 'headerText')}
            containerStyle={theme.forms.inputFluid}
            errorMessage={
              !formValidationStatus.headerText
                ? translate('form.maxCharReached', {
                    limit: HEADER_FOOTER_CHAR_LIMIT,
                  })
                : undefined
            }
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            multiline
            testID="input-footerText"
            title={translate('backOfficeReceiptTemplate.footerText')}
            value={form.footerText || ''}
            placeholder={translate(
              'backOfficeReceiptTemplate.placeholderInputText',
            )}
            onChangeText={onChange.bind(null, 'footerText')}
            containerStyle={theme.forms.inputFluid}
            errorMessage={
              !formValidationStatus.footerText
                ? translate('form.maxCharReached', {
                    limit: HEADER_FOOTER_CHAR_LIMIT,
                  })
                : undefined
            }
          />
        </View>
      </Section>
      <Section title={translate('backOfficeReceiptTemplate.socialsHeading')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-facebook"
            title="Facebook"
            value={form.socialLinks.facebook || ''}
            placeholder="facebook.com/username"
            onChangeText={onChange.bind(null, 'facebook')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-instagram"
            title="Instagram"
            label="@"
            value={form.socialLinks.instagram || ''}
            placeholder="username"
            onChangeText={onChange.bind(null, 'instagram')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-twitter"
            title="X (Twitter)"
            label="@"
            value={form.socialLinks.twitter || ''}
            placeholder="username"
            onChangeText={onChange.bind(null, 'twitter')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-website"
            title={translate('backOfficeReceiptTemplate.website')}
            value={form.socialLinks.website || ''}
            placeholder="example.com"
            onChangeText={onChange.bind(null, 'website')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeReceiptTemplate.featuresHeading')}>
        <View style={theme.forms.row}>
          <InputToggle
            type="switch"
            testID="toggle-oolioBranding"
            title={translate('backOfficeReceiptTemplate.oolioBranding')}
            subtitle={translate('backOfficeReceiptTemplate.oolioBrandingDesc')}
            isToggled={form.oolioBranding}
            onToggle={() => onChange('oolioBranding', !form.oolioBranding)}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};

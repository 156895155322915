import { DrawerScreenProps } from '@react-navigation/drawer';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { useSession } from '../../../hooks/app/useSession';
import { AppScreen } from '../../../types/AppScreen';
import PrintJobs from '../PrintingQueue/PrintingQueue';
import FloorView from './FloorView/FloorView';
import OnlineOrders from './OnlineOrders/OnlineOrders';
import OpenOrders from './OpenOrders/OpenOrders';
import PaymentScreen from '../Payments/PaymentScreen';
import TakeOrderLayout from './TakeOrder/TakeOrderLayout';
import { Platform } from 'react-native';

const Stack = createStackNavigator();

export type OrderStackParam = DrawerScreenProps<
  {
    OrderStack: { screen: string };
  },
  'OrderStack'
>;

const OrderStack: React.FC = () => {
  const [session] = useSession();
  const navigation = useNavigation();
  const route = useRoute<OrderStackParam['route']>();
  const { defaultSaleScreen } = session.deviceProfile || {};

  useFocusEffect(
    useCallback(() => {
      if (route.params?.screen) {
        navigation.navigate(route.params?.screen);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { state } = route as any;
      if (Array.isArray(state?.routes) && state?.routes.length > 0) {
        return;
      }
      if (defaultSaleScreen) {
        navigation.navigate(AppScreen[defaultSaleScreen]);
      }
      // Only depend on route params which come from navigation.navigate dispatch param
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.params]),
  );

  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName={'TakeOrder'} // Always mount TakeOrder at first screen
      screenOptions={{
        animationEnabled: false,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
      /**
       * White screen issue in stack navigator when used with react-native-screens.Android only.
       * For more read,https://github.com/software-mansion/react-native-screens/issues/111
       */
      mode={Platform.OS === 'android' ? 'modal' : undefined}
    >
      <Stack.Screen component={TakeOrderLayout} name="TakeOrder" />
      <Stack.Screen component={OpenOrders} name="OpenOrders" />
      <Stack.Screen component={PaymentScreen} name="Payment" />
      <Stack.Screen component={PrintJobs} name="PrintJobs" />
      <Stack.Screen component={FloorView} name="FloorView" />
      <Stack.Screen component={OnlineOrders} name="OnlineOrders" />
    </Stack.Navigator>
  );
};

export default OrderStack;

import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  inputContainer: {
    marginBottom: 20,
  },
  checkboxContainer: {
    flexDirection: 'row',
  },
  summaryContainer: {
    marginBottom: 20,
    width: '50%',
  },
  infoContainer: {
    maxWidth: 540,
    marginBottom: 10,
  },
  infoLabel: {
    paddingRight: 10,
  },
});

import { Dimensions, StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const { height: vh } = Dimensions.get('window');

const styles = StyleSheet.create({
  content: {
    width: '100%',
  },
  title: {
    flex: 1,
    fontSize: 16,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  search: {
    width: 620,
    marginTop: 40,
    marginBottom: 20,
  },
  loading: {
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  table: {
    maxHeight: vh - 300,
  },
  group: {
    marginBottom: 20,
  },
  rowParent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  textParent: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  },
  emptyText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
});

export default styles;

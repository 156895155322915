import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 380,
    alignSelf: 'center',
  },
  title: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    marginRight: 44,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
  },
  content: {
    padding: 30,
    marginTop: 8,
    maxHeight: 500,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  group: {
    marginBottom: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  typeText: {
    lineHeight: 26,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  amountText: {
    width: 70,
    marginLeft: 10,
    lineHeight: 26,
    textAlign: 'right',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  totalRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  totalText: {
    fontSize: 16,
    fontFamily: theme.fonts.medium,
  },
  failedPaymentIcon: {
    marginLeft: 5,
  },
});

export default styles;

import { useMutation, useReactiveVar } from '@apollo/client/react/hooks';
import {
  AcceptOrderEvent,
  CompleteOrderEvent,
  IntegrationApps,
  IntegrationPartner,
  OnlineOrderingPreferences,
  Order,
  OrderAction,
  OrderEvent,
  OrderStatus,
  RejectionReason,
  RejectOrderEvent,
} from '@oolio-group/domain';
import { computeOrderState } from '@oolio-group/order-helper';
import { useCallback, useMemo, useRef, useEffect } from 'react';
import { Tracer } from '@oolio-group/tracer-client';
import { ORDER_SAVE } from '../../../hooks/app/orders/graphql';
import { autoCompletedOrdersVar } from '../../../state/cache';
import { userUtility } from '../../../state/userUtility';
import { generateOrderEvent } from '../../../utils/orderEventHelper';
import kitchenOrderEvents from '../../../utils/printerTemplates/kotEvents';
import { useSession } from '../useSession';
import { getItem } from '../../../storage/interface';
import { INTEGRATION_SETTINGS_KEY } from '../../../types/Common';

export interface UseOnlineOrderEvents {
  acceptOrders: (orders: Order[]) => Promise<Order[]>;
  rejectOrder: (order: Order, reason: RejectionReason) => Order;
  completeOrder: (order: Order) => Promise<Order>;
}

export const useOnlineOrderEvents = (): UseOnlineOrderEvents => {
  const onSaveCompleteHandler = useRef<{ [orderId: string]: Function }>({});
  const preferenceRef = useRef<OnlineOrderingPreferences | undefined>();

  useEffect(() => {
    async function getPref() {
      const result = await getItem<IntegrationPartner[] | undefined>(
        INTEGRATION_SETTINGS_KEY,
      );
      const integrationPartner = result?.find(
        setting => setting.appName === IntegrationApps.OOLIO_STORE,
      );
      preferenceRef.current = integrationPartner?.preferences?.onlineOrdering;
    }
    getPref();
  }, []);

  const [saveOrder] = useMutation(ORDER_SAVE, {
    onCompleted: async ({ saveOrder }) => {
      onSaveCompleteHandler.current[saveOrder?.id]?.();
      delete onSaveCompleteHandler.current[saveOrder?.id];
    },
  });

  const [session] = useSession();
  const autoCompletedOrders = useReactiveVar<string[]>(autoCompletedOrdersVar);

  const eventSourceInfo = useMemo(
    () => ({
      organizationId: session.currentOrganization?.id,
      venueId: session.currentVenue?.id,
      deviceId: session.device?.id,
      storeId: session.currentStore?.id,
      triggeredBy: userUtility?.posUser?.id || session.user?.id,
    }),
    [session],
  );

  const saveAndPrintOrder = useCallback(
    async (order: Order, events: OrderEvent[]) => {
      onSaveCompleteHandler.current[order.id] = () => {
        kitchenOrderEvents.publishToKotUtil({
          orderId: order.id,
          preEvents: events,
        });
      };
      await saveOrder({ variables: { data: order } });
    },
    [saveOrder],
  );

  const completeOrder = useCallback(
    async (order: Order): Promise<Order> => {
      const event = generateOrderEvent<CompleteOrderEvent>(
        OrderAction.ORDER_COMPLETE,
        eventSourceInfo,
        {
          orderId: order?.id,
          previous: order?.prevEventId,
          integrationApp: order.integrationInfo?.app,
          integrationInfo: {
            app: order.integrationInfo?.app as IntegrationApps,
            id: order.integrationInfo?.id as string,
          },
          orderNumber: order.orderNumber,
        },
      );

      const updatedOrder = computeOrderState([event], order);
      await saveAndPrintOrder(updatedOrder, [event]);
      autoCompletedOrdersVar([...autoCompletedOrders, order.id]);

      Tracer.getInstance().debug('Completing online order', 'ORDER_EVENT', {
        path: 'pos-app/src/hooks/app/orders/useOnlineOrderEvents.ts',
        additionalData: {
          orderEvent: event,
          orderCurrentState: order,
          orderNewState: updatedOrder,
        },
      });
      return updatedOrder;
    },
    [eventSourceInfo, saveAndPrintOrder, autoCompletedOrders],
  );

  const acceptOrders = useCallback(
    async (orders: Order[]): Promise<Order[]> => {
      return Promise.all(
        orders.map(async order => {
          const event = generateOrderEvent<AcceptOrderEvent>(
            OrderAction.ORDER_ACCEPT,
            eventSourceInfo,
            {
              orderId: order?.id,
              previous: order?.prevEventId,
              integrationApp: order.integrationInfo?.app,
              integrationInfo: {
                app: order.integrationInfo?.app as IntegrationApps,
                id: order.integrationInfo?.id as string,
              },
              orderNumber: order.orderNumber,
            },
          );

          onSaveCompleteHandler.current[order.id] = () => {
            kitchenOrderEvents.publishToKotUtil({
              orderId: order.id,
              preEvents: [event],
            });
          };
          const updatedOrder = computeOrderState([event], order);
          let returnData = updatedOrder;
          await saveOrder({ variables: { data: updatedOrder } });
          if (
            preferenceRef?.current?.autoCompleteOrders === true &&
            preferenceRef?.current?.autoCompletePeriod === 0 &&
            updatedOrder?.amountDue !== undefined &&
            updatedOrder?.amountDue <= 0 &&
            updatedOrder?.status === OrderStatus.IN_PROGRESS
          ) {
            returnData = await completeOrder(updatedOrder);
          }
          Tracer.getInstance().debug('Accepting online order', 'ORDER_EVENT', {
            path: 'pos-app/src/hooks/app/orders/useOnlineOrderEvents.ts',
            additionalData: {
              orderEvent: event,
              orderCurrentState: order,
              orderNewState: updatedOrder,
            },
          });
          return returnData;
        }),
      );
    },
    [eventSourceInfo, saveOrder, completeOrder],
  );

  const rejectOrder = useCallback(
    (order: Order, reason: RejectionReason): Order => {
      const event = generateOrderEvent<RejectOrderEvent>(
        OrderAction.ORDER_REJECT,
        eventSourceInfo,
        {
          orderId: order?.id,
          previous: order?.prevEventId,
          reason,
          integrationApp: order.integrationInfo?.app,
          integrationInfo: {
            app: order.integrationInfo?.app as IntegrationApps,
            id: order.integrationInfo?.id as string,
          },
          orderNumber: order.orderNumber,
        },
      );

      const updatedOrder = computeOrderState([event], order);
      saveAndPrintOrder(updatedOrder, [event]);
      return updatedOrder;
    },
    [saveAndPrintOrder, eventSourceInfo],
  );

  return useMemo(
    () => ({
      acceptOrders,
      completeOrder,
      rejectOrder,
    }),
    [acceptOrders, completeOrder, rejectOrder],
  );
};

import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    alignSelf: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  icon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    flex: 1,
    marginRight: 44,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    padding: 20,
    marginTop: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  input: {
    height: 80,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.teritiary3,
  },
  inputText: {
    fontSize: 28,
    lineHeight: 32,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
    color: theme.colors.states.focus,
  },
  btnClear: {
    position: 'absolute',
    right: 20,
  },
  keypad: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  btnKeypad: {
    height: 70,
    marginBottom: '2%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  textKeypad: {
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
  },
});

export default styles;

import React, { useCallback, useEffect, useState } from 'react';
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import styles from './AddNote.styles';
import Icon from '../../../Icon/Icon';
import InputText from '../../../Shared/Inputs/InputText';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

export interface AddNoteModalProps {
  title?: string;
  value?: string;
  maxLength?: number;
  readonly?: boolean;
  onSubmit?: (note: string) => void;
  onClose?: () => void | Promise<void>;
}

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  title,
  value,
  maxLength = 200,
  readonly,
  onSubmit,
  onClose,
}: AddNoteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [formState, setFormState] = useState(value || '');

  const onCloseModal = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  const onSubmitNote = useCallback(() => {
    closeModal();
    onSubmit && onSubmit(formState);
  }, [closeModal, onSubmit, formState]);

  useEffect(() => {
    if (value) {
      setFormState(value);
    }
  }, [value]);

  const modalTitle = !!title ? title : translate('modal.addNote');

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.container}>
        <View style={styles.title}>
          <TouchableOpacity
            testID="btn-close"
            style={styles.btnClose}
            onPress={onCloseModal}
          >
            <Icon name="times" size={20} color={theme.colors.dark} />
          </TouchableOpacity>
          <Text style={styles.titleText}>{modalTitle}</Text>
        </View>
        <View style={styles.content}>
          <InputText
            testID="input-note"
            autoFocus={true}
            multiline
            editable={!readonly}
            value={formState}
            placeholder={modalTitle + '...'}
            onChangeText={setFormState}
            maxLength={maxLength}
          />
          <TreatButton
            testID="btn-confirm"
            type={readonly ? 'neutral' : 'positive'}
            label={
              readonly ? translate('button.close') : translate('button.save')
            }
            onPress={onSubmitNote}
            containerStyle={styles.btnConfirm}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default AddNoteModal;

import { GQLClientResponse } from '@oolio-group/gql-client';
import { ApolloError } from '@apollo/client';
import { Tracer } from '@oolio-group/tracer-client';

export function parseApolloError(error: ApolloError): string {
  if (!error) {
    return '';
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return error.graphQLErrors
      .map(({ message }) => {
        Tracer.getInstance().captureMessage(message);
        return message;
      })
      .join(', ');
  } else if (error.message) {
    Tracer.getInstance().captureException(error);
    return error.message;
  } else {
    Tracer.getInstance().captureException(error);
    return 'Network Error';
  }
}

export function parseGraphQLError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: GQLClientResponse<object>,
): string {
  return parseApolloError(response.error);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noopHandler(): void {}

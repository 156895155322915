/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text } from 'react-native';

const Cash: React.FC = () => {
  return (
    <View
      style={{
        backgroundColor: '#E7F5E9',
        width: 30,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{ fontFamily: 'Inter-Bold', fontSize: 12, color: '#439F46' }}
      >
        C
      </Text>
    </View>
  );
};

export default Cash;

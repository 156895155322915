import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 40,
  },
  tableBody: {
    marginBottom: 20,
  },
  cellGroup: {
    width: 240,
    marginRight: 10,
  },
  cellMargin: {
    width: 120,
    marginRight: 'auto',
  },
  headerMargin: {
    width: 100,
    textAlign: 'center',
  },
});

export default styles;

/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import theme from '../../../../common/default-theme';
import React from 'react';
import { View, Text } from 'react-native';

const Other: React.FC = () => {
  return (
    <View
      style={{
        backgroundColor: theme.colors.grey1,
        width: 30,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text style={{ fontFamily: 'Inter-Bold', fontSize: 12, color: '#000' }}>
        O
      </Text>
    </View>
  );
};

export default Other;

import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { TerminalDiagnosisInfo } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './TerminalDiagnosis.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import {
  TerminalDiagnosisRow,
  TerminalDiagnosisRowProps,
} from './TerminalDiagnosisRow';

export type TerminalDiagnosisProps = {
  diagnosisData?: TerminalDiagnosisInfo;
};

const TerminalDiagnosis: React.FC<TerminalDiagnosisProps> = ({
  diagnosisData,
}: TerminalDiagnosisProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const getDataToDisplay = useCallback(() => {
    if (diagnosisData) {
      return (Object.keys(diagnosisData) as [keyof TerminalDiagnosisInfo])
        .filter(label => {
          if (
            diagnosisData[label as keyof TerminalDiagnosisInfo] === null ||
            diagnosisData[label as keyof TerminalDiagnosisInfo] === undefined
          ) {
            return false;
          }
          return true;
        })
        .map(label => (
          <TerminalDiagnosisRow
            label={label}
            key={`diagnosis-info-row-${label}`}
            value={diagnosisData[label] as TerminalDiagnosisRowProps['value']}
          />
        ));
    }

    return [];
  }, [diagnosisData]);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={onPressClose}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {translate('paymentTerminal.diagnosis.header')}
        </Text>
      </View>
      <View style={styles.content}>{getDataToDisplay()}</View>
    </View>
  );
};

export default TerminalDiagnosis;

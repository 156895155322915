import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Resource, AppScreen, MoneyMovement } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useQuery } from '@apollo/client/react/hooks';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { GET_MONEY_MOVEMENTS } from '../../../graphql/manageMoney';
import { useNotification } from '../../../hooks/Notification';
import { usePrinting } from '../../../hooks/PrintingProvider';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { parseApolloError } from '../../../utils/errorHandlers';
import styles from './ManageCash.styles';
import Search from '../../../components/Shared/Search/Search';
import TreatPicker from '../../../components/Shared/Select/Picker';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import AddEventModal from '../../../components/POS/Modals/AddMoneyEvent/AddMoneyEvent';
import ManageCashTable from './Table/ManageCashTable';

enum MovementTypeFilter {
  showAll = 'ShowAll',
  moneyIn = 'MONEY_IN',
  moneyOut = 'MONEY_OUT',
}

const ManageCash: React.FC = () => {
  const { showModal } = useModal();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { canI } = usePOSUserAuthorization();
  const { showNotification } = useNotification();
  const { printMoneyMovementReceipt, openCashDrawer } = usePrinting();
  const {
    data: moneyMovements,
    error: moneyMovementsError,
    refetch,
    client,
  } = useQuery(GET_MONEY_MOVEMENTS, { fetchPolicy: 'cache-and-network' });

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<MovementTypeFilter>(
    MovementTypeFilter.showAll,
  );

  const onPressAddEvent = useCallback(
    () =>
      showModal(
        <AddEventModal
          openCashDrawer={openCashDrawer}
          printMoneyMovementReceipt={printMoneyMovementReceipt}
        />,
      ),
    [openCashDrawer, printMoneyMovementReceipt, showModal],
  );

  useEffect(() => {
    if (moneyMovementsError) {
      showNotification({
        error: true,
        message: parseApolloError(moneyMovementsError),
      });
    }
  }, [moneyMovementsError, showNotification]);

  useEffect(() => {
    if (moneyMovements) {
      client.writeQuery({
        query: GET_MONEY_MOVEMENTS,
        data: { moneymovements: moneyMovements },
      });
    }
  });

  useEffect(() => {
    isFocused && refetch();
  }, [isFocused, refetch]);

  useEffect(() => {
    if (isFocused) {
      const hasAccess = canI([{ onResource: Resource.ALLOW_MONEY_MOVEMENTS }], {
        prompt: false,
      });
      if (!hasAccess) {
        navigation.navigate('Orders', {
          screen: { name: AppScreen.NEW_ORDER },
        });
      }
    }
  }, [canI, navigation, isFocused]);

  const movementTypes = useMemo(
    () => [
      {
        value: MovementTypeFilter.showAll,
        label: translate('moneyMovements.allTypes'),
      },
      {
        value: MovementTypeFilter.moneyIn,
        label: translate('moneyMovements.moneyIn'),
      },
      {
        value: MovementTypeFilter.moneyOut,
        label: translate('moneyMovements.moneyOut'),
      },
    ],
    [translate],
  );

  const getMovementByType = (
    movements: MoneyMovement[],
    movementFilter: string,
  ): MoneyMovement[] => {
    if (movementFilter === MovementTypeFilter.showAll) return movements;
    return movements.filter(movement => movement.eventType === movementFilter);
  };

  const getMovementFromSearch = (
    movements: MoneyMovement[],
    searchQuery: string,
  ): MoneyMovement[] => {
    return movements.filter(movement =>
      movement.user.name.toLowerCase()?.includes(searchQuery.toLowerCase()),
    );
  };

  const filteredMovements = useMemo(() => {
    let filteredList =
      moneyMovements && moneyMovements.moneyMovements
        ? moneyMovements.moneyMovements
        : [];

    if (searchFilter) {
      filteredList = getMovementFromSearch(filteredList, searchFilter);
    }

    if (typeFilter) {
      filteredList = getMovementByType(filteredList, typeFilter);
    }

    return filteredList;
  }, [moneyMovements, searchFilter, typeFilter]);

  return (
    <ScreenLayout
      loading={!moneyMovements}
      title={translate('storesSettings.tabNames.manageCash')}
      onBack={() => navigation.goBack()}
    >
      <View style={styles.filters}>
        <TreatPicker
          testID="select-type"
          options={movementTypes}
          selectedValue={typeFilter}
          containerStyle={styles.dropdown}
          onValueChange={setTypeFilter}
        />
        <Search
          testID="search-events"
          containerStyle={styles.search}
          onChangeText={setSearchFilter}
          placeholder={translate('moneyMovements.searchPlaceholder')}
        />
        <TreatButton
          type="positive"
          testID="btn-addEvent"
          label={translate('moneyMovements.addNewEvent')}
          onPress={onPressAddEvent}
        />
      </View>
      <ManageCashTable data={filteredMovements} />
    </ScreenLayout>
  );
};

export default ManageCash;

import { StyleFn } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { useSession } from '../../../hooks/app/useSession';
import IconButton from '../../Button/IconButton';
import { PickerModal } from '../Picker/Picker';

interface SectionsSelectModalProps {
  onSelectSection: (sectionId: string) => void;
  onClose?: () => void;
}

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const SectionsSelectModal: React.FC<SectionsSelectModalProps> = ({
  onSelectSection,
  onClose,
}: SectionsSelectModalProps) => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const [{ deviceProfile }] = useSession();
  const listSections = useMemo(
    () =>
      deviceProfile?.sections?.map(x => ({
        label: x.name,
        value: x.id,
      })) ?? [],
    [deviceProfile?.sections],
  );

  return (
    <PickerModal
      onSelect={onSelectSection}
      title={translate('order.selectSection')}
      options={listSections}
      closeBtn={
        <IconButton
          icon="arrow-left"
          containerStyle={css(closeButtonStyle)}
          onPress={onClose}
        />
      }
    />
  );
};

export default SectionsSelectModal;

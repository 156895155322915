import React, { useCallback, useEffect, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import {
  PaymentType,
  OrderPaymentStatus,
  Order,
  OrderStatus,
  AdditionalPaymentInfo,
  TempPaymentInfo,
  PAYMENT_INPROGRESS_MESSAGES,
  OrderEvent,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { DEFAULT_PAYMENT_CURRENCY } from '../../types/Common';
import { usePayments } from '../../hooks/app/usePayments';
import { useSession } from '../../hooks/app/useSession';
import { useNetworkStatusVar } from '../../hooks/app/useNetworkStatusVar';
import { useNotification } from '../../hooks/Notification';
import ProcessCardPaymentModal from '../../components/POS/Modals/ProcessCardPayment/ProcessCardPaymentModal';
import { stripProperties } from '../../utils/stripObjectProps';
import { isOrderEditable } from '@oolio-group/order-helper';
import { v4 as uuidv4 } from 'uuid';
import { userUtility } from '../../state/userUtility';

const nanoid = customAlphabet(alphanumeric, 10);

interface BasicOrderDetails {
  orderId: string;
  orderAmount: number;
  orderNumber: string;
}

interface CreateCardPaymentInput {
  orderInfo: BasicOrderDetails;
  paymentType: PaymentType;
  surchargeAmount?: number;
  pendingPaymentRequestId?: string;
}

export interface UseCardPayFunctionMapRes {
  createCardPayment: (input: CreateCardPaymentInput) => void;
}

export const useCardPayFunctionMap = (
  order?: Order,
  onPressPay?: (
    paymentType: PaymentType,
    amount: number,
    surchargeAmount?: number,
    additionalInfo?: AdditionalPaymentInfo,
    eventId?: string,
  ) => void,
  onPaymentProcessed?: (events: OrderEvent[]) => void,
  openOrderCart?: (orderId: string) => void,
): UseCardPayFunctionMapRes => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const [session] = useSession();
  const isFocused = useIsFocused();

  const paymentDetailsRef = useRef<TempPaymentInfo | undefined>(undefined);
  const showingCardPaymentModal = useRef(false);
  const networkStatus = useNetworkStatusVar();
  // Using useRef here because callback sent to showModal is getting previous state
  // State was not getting updated in the callback function sent to showModal
  const networkStatusRef = useRef<boolean>(true);
  networkStatusRef.current = networkStatus;

  const {
    loading: isPaymentLoading,
    cancellationLoading,
    error: paymentError,
    paymentResponse,
    cancellationResponse,
    initiatePayment,
    verifyPayment,
    resetPaymentResponse,
    cancelPayment,
    resetCancellationResponse,
  } = usePayments();

  useEffect((): void => {
    if (paymentError) {
      showNotification(
        {
          error: true,
          message: paymentError,
        },
        false,
      );
    }
  }, [paymentError, showNotification]);

  const ifNoNetwork = useCallback(() => {
    if (!networkStatusRef.current) {
      showNotification({
        error: true,
        message: translate('settings.noInternet'),
      });
      return true;
    }
    return false;
  }, [showNotification, translate]);

  useEffect(() => {
    if (paymentResponse) {
      // TODO: Cancel original "initiatePayment" request, if success is provided by Verify.
      const isPaymentStillInProgress = PAYMENT_INPROGRESS_MESSAGES.includes(
        paymentResponse.message,
      );

      if (!paymentResponse.success) {
        showNotification(
          {
            ...(!isPaymentStillInProgress && { error: true }),
            ...(isPaymentStillInProgress && { info: true }),
            message: paymentResponse.message,
          },
          false,
        );
      }

      resetPaymentResponse();

      if (!isPaymentStillInProgress) {
        onPaymentProcessed &&
          onPaymentProcessed(paymentResponse.events as OrderEvent[]);
      }
    }
  }, [
    closeModal,
    isPaymentLoading,
    onPaymentProcessed,
    paymentResponse,
    resetPaymentResponse,
    showNotification,
    translate,
  ]);

  const initiateCardPayment = useCallback(
    (orderInfo: BasicOrderDetails, surchargeAmount: number) => {
      if (!ifNoNetwork()) {
        const requestId = nanoid();
        const newEventId = uuidv4();

        initiatePayment({
          deviceId: session.device?.deviceCode as string,
          terminalId: session.device?.paymentTerminal?.uuid as string,
          requestId: requestId,
          transactionId: orderInfo.orderNumber,
          amount: paymentDetailsRef.current?.orderAmount as number,
          currency:
            session.currentOrganization?.currencyCode ||
            DEFAULT_PAYMENT_CURRENCY,
          includeTips: session.currentStore?.checkoutOptions?.enableTips,
          orderInfo: {
            orderId: paymentDetailsRef.current?.orderId as string,
            lastEventId: newEventId,
            activeUser: (userUtility.posUser?.id ||
              userUtility?.recentUserId) as string,
            lastEventTimestamp: Date.now(),
          },
        });

        onPressPay &&
          onPressPay(
            paymentDetailsRef.current?.paymentType as PaymentType,
            paymentDetailsRef.current?.orderAmount as number,
            surchargeAmount,
            {
              paymentRequestId: requestId,
              paymentStatus: OrderPaymentStatus.PENDING,
            },
            newEventId,
          );

        paymentDetailsRef.current = {
          ...(paymentDetailsRef.current as TempPaymentInfo),
          requestId,
        };
      }
    },
    [
      ifNoNetwork,
      initiatePayment,
      onPressPay,
      session.currentStore?.checkoutOptions?.enableTips,
      session.device?.deviceCode,
      session.device?.paymentTerminal?.uuid,
      session.currentOrganization?.currencyCode,
    ],
  );

  const verifyCardPayment = useCallback(() => {
    if (!ifNoNetwork() && paymentDetailsRef.current?.requestId) {
      verifyPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId,
        orderInfo: {
          orderId: paymentDetailsRef.current?.orderId as string,
          lastEventId: order?.prevEventId as string,
          activeUser: (userUtility.posUser?.id ||
            userUtility?.recentUserId) as string,
        },
      });
    }
  }, [ifNoNetwork, order?.prevEventId, session.device, verifyPayment]);

  const cancelCardPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      /**
       * Cancel payment is acknowledgement only.
       * The actual cancellation is verified by original request or
       * verify status.
       */
      cancelPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });
      openOrderCart?.(order?.id as string);
    }

    /**
     * Sending close modal action for scenarios where internet is up but
     * 3th party api is not retuning any response (due to network drop-out).
     * Staff will be able to close order by cash in such cases.
     */
    setTimeout(() => closeModal(), 4000);
  }, [
    ifNoNetwork,
    cancelPayment,
    session.device?.deviceCode,
    session.device?.paymentTerminal?.uuid,
    openOrderCart,
    order?.id,
    closeModal,
  ]);

  useEffect(() => {
    if (cancellationResponse) {
      showNotification({
        success: true,
        message: translate('payment.oolioPayCancellationSuccess'),
      });

      resetCancellationResponse();

      if (!isPaymentLoading) verifyCardPayment();
    }
  }, [
    cancellationResponse,
    closeModal,
    isPaymentLoading,
    resetCancellationResponse,
    showNotification,
    translate,
    verifyCardPayment,
  ]);

  useEffect(() => {
    const pendingPayment = (order?.payments || []).find(
      payment => payment.status === OrderPaymentStatus.PENDING,
    );
    const isEditable = isOrderEditable(order?.status as OrderStatus);
    const isSameUpdateDevice =
      pendingPayment?.updatedByDeviceId === session.device?.id;

    if (!pendingPayment || !isFocused || !isSameUpdateDevice || !isEditable)
      return;

    showingCardPaymentModal.current = true;

    paymentDetailsRef.current = {
      paymentType: pendingPayment.paymentType as PaymentType,
      orderAmount: pendingPayment.tendered,
      requestId: pendingPayment.paymentRequestId as string,
      orderId: order?.id,
    };

    showModal(
      <ProcessCardPaymentModal
        orderTotal={pendingPayment.tendered as number}
        cancellationLoading={cancellationLoading}
        onPressCancel={cancelCardPayment}
        onPressVerify={verifyCardPayment}
      />,
    );

    const autoVerifyTimeout = setTimeout(() => {
      if (pendingPayment && !isPaymentLoading) {
        showNotification({
          info: true,
          message: translate('payment.verifyPendingPayment'),
        });
        verifyCardPayment();
      }
    }, 2000);

    return () => {
      if (showingCardPaymentModal.current) {
        showingCardPaymentModal.current = false;
        closeModal();
      }
      clearTimeout(autoVerifyTimeout);
    };
  }, [
    cancelCardPayment,
    isPaymentLoading,
    cancellationLoading,
    showModal,
    verifyCardPayment,
    closeModal,
    order?.payments,
    order?.status,
    session.device?.id,
    isFocused,
    showNotification,
    translate,
    order?.id,
  ]);

  const createCardPayment = useCallback(
    (input: CreateCardPaymentInput) => {
      const { surchargeAmount = 0, orderInfo, paymentType } = input;

      if (orderInfo.orderAmount === 0) {
        showNotification({
          message: translate('payment.payZeroError'),
          error: true,
        });
        return;
      }
      if (session.device?.paymentTerminal) {
        const paymentTypeInput = stripProperties(
          { ...paymentType },
          'label',
          'value',
        );

        paymentDetailsRef.current = {
          paymentType: paymentTypeInput,
          orderAmount: orderInfo.orderAmount,
          orderId: orderInfo.orderId,
        };

        initiateCardPayment(orderInfo, surchargeAmount || 0);
      } else {
        showNotification({
          message: translate('payment.paymentTerminalNotLinked'),
          error: true,
        });
      }
    },
    [
      initiateCardPayment,
      session.device?.paymentTerminal,
      showNotification,
      translate,
    ],
  );

  return {
    createCardPayment,
  };
};

import { useLazyQuery } from '@apollo/client/react/hooks';
import { useMemo, useState, useCallback } from 'react';
import { GET_SALES_CHANNELS_QUERY } from './graphql';
import { parseApolloError } from '../../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';
import { SalesChannel } from '@oolio-group/domain';
import { noopHandler } from '../../../utils/errorHandlers';

export interface UseSalesChannelProps {
  salesChannels: Record<string, SalesChannel>;
  error: string | undefined;
  loading: boolean;
  getSalesChannels: () => void;
  inStoreSaleChannel?: SalesChannel;
}

export function useSalesChannels(): UseSalesChannelProps {
  const [salesChannels, setSalesChannels] = useState<
    Record<string, SalesChannel>
  >({});

  const onCompleteGetSalesChannelsRequest = useCallback(
    data => {
      if (data) {
        setSalesChannels(keyBy(data.salesChannels as SalesChannel[], 'id'));
      }
    },
    [setSalesChannels],
  );

  const [getSalesChannels, getSalesChannelsResponse] = useLazyQuery(
    GET_SALES_CHANNELS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onCompleted: onCompleteGetSalesChannelsRequest,
      onError: noopHandler,
    },
  );

  const inStoreSaleChannel = useMemo(
    () =>
      Object.values(salesChannels).find(
        saleChannel => saleChannel.name.toLowerCase() === 'in store',
      ),
    [salesChannels],
  );

  const error: ApolloError | undefined = getSalesChannelsResponse.error;

  const loading: boolean = getSalesChannelsResponse.loading;

  return useMemo(
    () => ({
      salesChannels,
      error: error ? parseApolloError(error) : undefined,
      loading,
      getSalesChannels,
      inStoreSaleChannel,
    }),
    [salesChannels, error, loading, getSalesChannels, inStoreSaleChannel],
  );
}
